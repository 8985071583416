<template>
  <ValidationObserver v-slot="{ errors, validate }">
    <Modal
      sheetbelowsm
      :title="mode === 'Edit' ? $t('projects.variables.edit') : $t('projects.variables.add')"
      :primary-button="$t('save')"
      :secondary-button="$t('cancel')"
      :primary-button-loading="loading"
      close-button
      @primary="onSaveClicked(validate)"
      @close="onCancelClicked"
      @secondary="onCancelClicked"
    >
      <div class="sm:w-600 px-10">
        <div class="w-full flex flex-col justify-start mt-5">
          <FormInput
            v-model="name"
            name="name"
            :error="errors && errors.name"
            :rules="[{ validate: variableNameUnique }, 'required']"
            :label="$t('collections.variable_name')"
            :readonly="mode === 'Edit'"
            spellcheck="false"
          />
        </div>
        <div class="w-full flex flex-col justify-start mt-5">
          <FormSelect
            v-model="dataType"
            :options="dataTypes"
            name="datatype"
            :error="errors && errors.datatype"
            rules="required"
            :label="$t('collections.variable_data_type')"
            appendToBody
            :reduce="selectReducer"
            :clearable="false"
            @option:selected="onDataTypeInput"
          ></FormSelect>
        </div>
        <div class="w-full flex flex-col justify-start mt-5">
          <FormLabel :label="$t('collections.set_initial_value')"></FormLabel>
          <Toggle v-model="setInitialValue" :disabled="!hasSupportInitialValue" />
        </div>
        <div v-if="setInitialValue && hasSupportInitialValue" class="w-full flex flex-col justify-start mt-5">
          <FormLabel :label="$t('collections.initial_value')" />

          <FormInput
            v-if="['Text', 'Number'].includes(dataType)"
            v-model="initialValue"
            name="initialValue"
            rules="required"
            :error="errors.initialValue"
            :type="dataType === 'Number' ? 'number' : 'text'"
          ></FormInput>
          <FormSelect
            v-else-if="dataType === 'YesNo'"
            v-model="initialValue"
            :options="['Yes', 'No']"
            name="initialValue"
            rules="required"
            :error="errors.initialValue"
            appendToBody
            class="mt-3"
          ></FormSelect>
          <div v-else-if="dataType === 'DataTable'" class="w-full relative border h-320">
            <div class="flex h-5">
              <div class="text-13 cursor-pointer w-16 text-center border-r" :class="{ 'border-b': tab !== 'editor' }" @click="tab = 'editor'">{{ $t('editor') }}</div>
              <div class="text-13 cursor-pointer w-16 text-center border-r" :class="{ 'border-b': tab !== 'preview' }" @click="tab = 'preview'">{{ $t('preview') }}</div>
              <div class="flex-grow border-b"></div>
              <div v-show="tab === 'editor'" class="text-13 cursor-pointer border-b border-l px-2" @click="formatEditor">{{ $t('format_code') }}</div>
            </div>
            <div class="h-[298px]">
              <MonacoEditor v-show="tab === 'editor'" width="100%" height="298" v-model="initialValue" language="json" :options="editorOptions" :editorMounted="monacoMounted"></MonacoEditor>
              <div class="w-full h-full overflow-auto px-1" v-show="tab === 'preview'">
                <DataTable v-if="Array.isArray(dataTableValue)" :value="dataTableValue" class="w-full" />
              </div>
            </div>
          </div>
          <div v-else-if="hasFields">
            <FormSelect v-model="initialValue" :options="dataTypeFields" name="initialValue" rules="required" :error="errors.initialValue" appendToBody></FormSelect>
          </div>
        </div>
      </div>
    </Modal>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MonacoEditor from 'monaco-editor-vue';
import { apiPostVariableDefinition, apiPutVariableDefinition } from '@/helpers/api';
import { MONACO_EDITOR_DEFAULTS } from '@/constants';
import ValidationObserver from '@/components/ui/ValidationObserver.vue';
import FormLabel from '@/components/ui/FormLabel.vue';

export default {
  name: 'AddEditVariableModal',
  props: {
    variableName: String,
  },
  components: { FormLabel, ValidationObserver, MonacoEditor },
  data() {
    return {
      mode: 'Add',
      originalName: '',
      name: '',
      dataType: '',
      datatypeName: '',
      loading: false,
      initialValue: null,
      setInitialValue: false,
      tab: 'editor',
      editor: null,
      editorOptions: MONACO_EDITOR_DEFAULTS,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId']),
    ...mapGetters(['getActiveDatasourceDataTypes', 'getNoCodeProjectVariables', 'getActiveDatasourceVariables']),
    hasSupportInitialValue() {
      return ['Text', 'Number', 'YesNo', 'DataTable'].includes(this.dataType) || this.hasFields;
    },
    hasFields() {
      return this.getActiveDatasourceDataTypes[this.dataType]?.fields;
    },
    dataTypeFields() {
      const fields = this.getActiveDatasourceDataTypes[this.dataType]?.fields;
      return Object.keys(fields);
    },
    entities() {
      return Object.keys(this.getActiveDatasourceDataTypes);
    },
    variables() {
      if (this.variableSelectorType === 'no-code') {
        return this.getNoCodeProjectVariables;
      }
      return this.getActiveDatasourceVariables;
    },
    dataTypes() {
      const entities = this.entities.map((entity) => ({ value: entity, label: entity }));
      return [...entities].sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    },
    dataTableValue() {
      try {
        return JSON.parse(this.initialValue);
      } catch {
        return [];
      }
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchVariableDefinition']),
    variableNameUnique() {
      if (this.mode === 'Edit' && this.name !== this.originalName) {
        if (Object.keys(this.variables).includes(this.name)) {
          return this.$t('projects.variables.must_be_unique');
        }
      }
      if (this.mode === 'Add') {
        if (Object.keys(this.variables).includes(this.name)) {
          return this.$t('projects.variables.must_be_unique');
        }
      }
      return true;
    },
    selectReducer(option) {
      return option.value;
    },
    onDataTypeInput() {
      if (!this.hasSupportInitialValue) {
        this.setInitialValue = false;
      }
      this.initialValue = null;
    },
    formatEditor() {
      this.editor.getAction('editor.action.formatDocument').run();
    },
    monacoMounted(editor) {
      this.editor = editor;
    },
    onCancelClicked() {
      this.$emit('close');
      this.$modal?.close();
    },
    async onSaveClicked(validate) {
      const valid = await validate();
      if (!valid.valid) return;

      // eslint-disable-next-line
      let initialValue = this.initialValue;
      if (this.dataType === 'DataTable') {
        try {
          initialValue = JSON.parse(this.initialValue);
        } catch {
          initialValue = null;
        }
      }
      this.loading = true;
      if (this.mode === 'Add') {
        const response = await apiPostVariableDefinition({
          name: this.name,
          type: this.designTimeActiveDatasourceType,
          model_id: this.designTimeActiveDatasourceModelId,
          dataType: this.dataType,
          value: this.hasSupportInitialValue && this.setInitialValue ? initialValue : null,
        });
        if (response.status === 200) {
          this.showToastMessage({ message: this.$t('projects.variable_added_successfully'), type: 'success' });
          this.$emit('saveintent', response.data);
          this.$modal?.close();
          return;
        }
        this.showToastMessage({ title: this.$t('projects.failed_to_add_variable'), type: 'error' });
        this.loading = false;
        return;
      }
      if (this.mode === 'Edit') {
        const response = await apiPutVariableDefinition({
          name: this.name,
          type: this.designTimeActiveDatasourceType,
          model_id: this.designTimeActiveDatasourceModelId,
          dataType: this.dataType,
          revision: 1,
          value: this.hasSupportInitialValue && this.setInitialValue ? initialValue : null,
        });
        if (response.status === 200) {
          this.showToastMessage({ message: this.$t('projects.variable_updated_successfully'), type: 'success' });
          this.$emit('saveintent', response.data);
          this.$modal?.close();
          return;
        }
        this.showToastMessage({ title: this.$t('projects.failed_to_update_variable'), type: 'error' });
        this.loading = false;
      }
    },
    async getVariableDefinition() {
      const response = await this.fetchVariableDefinition({ name: this.variableName, type: this.designTimeActiveDatasourceType });
      this.dataType = response?.dataType || '';
      if (response.value) {
        if (this.dataType === 'DataTable') {
          this.initialValue = JSON.stringify(response.value);
        } else {
          this.initialValue = response.value;
        }
      }
      this.setInitialValue = !!response.value;
    },
  },
  created() {
    if (this.variableName && this.variableName.length) {
      this.mode = 'Edit';
      this.originalName = this.variableName;
      this.name = this.variableName;
      this.getVariableDefinition();
    } else {
      this.mode = 'Add';
      this.originalName = '';
      this.name = '';
    }
  },
};
</script>
