<template>
  <div class="accordion box" role="presentation">
    <slot />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */

export default {
  name: 'Accordion',
  props: {
    value: {
      type: [Number, Array],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupId: null,
      reformatData: [],
      activeIndex: 0,
      activeIds: [],
    };
  },
  mounted() {
    if (this.value !== null || this.value !== undefined) {
      if (Array.isArray(this.value)) {
        this.$slots.default.forEach((slot, index) => {
          if (this.value.includes(index)) {
            // eslint-disable-next-line
            this.activeIds.push(slot.componentInstance._uid);
          }
        });
      } else if (Number.isInteger(this.value)) {
        this.activeIds.push(this.$slots.default[this.value].componentInstance._uid);
      }
    }
    this.$on('change', (e) => {
      const { active, uid } = e;
      /* eslint-disable  no-lonely-if */
      if (this.multiple) {
        if (active) {
          this.activeIds.push(uid);
        } else {
          const i = this.activeIds.indexOf(uid);
          this.activeIds.splice(i, 1);
        }
        const ids = this.activeIds.map((id) => this.getIndex(id));
        this.$emit('input', ids);
      } else {
        if (active) {
          this.$set(this, 'activeIds', [uid]);
          this.$emit('input', this.getIndex(uid));
        } else {
          this.$set(this, 'activeIds', []);
          this.$emit('input', null);
        }
      }
    });
  },
  computed: {
    accordionClasses() {
      return {
        'is-closed': !this.isOpen,
        'is-primary': this.isOpen,
        'is-dark': !this.isOpen,
      };
    },
  },
  methods: {
    getIndex(uid) {
      // eslint-disable-next-line
      return this.$slots.default.findIndex((c) => c.componentInstance._uid === uid);
    },
    addUid(uid) {
      if (!this.activeIds.includes(uid)) {
        this.activeIds.push(uid);
      }
    },
  },
  watch: {
    value: {
      async handler() {
        if (this.value !== null || this.value !== undefined) {
          if (Array.isArray(this.value)) {
            this.$slots.default.forEach((slot, index) => {
              if (this.value.includes(index)) {
                // eslint-disable-next-line
                this.addUid(slot.componentInstance._uid);
              }
            });
          } else if (Number.isInteger(this.value)) {
            await this.$nextTick();
            this.addUid(this.$slots.default[this.value].componentInstance._uid);
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
$browser-context: 16;
$blue: #1779ba;
$gray: #cacaca;
$red: #cc4b37;
$white: #ffffff;

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

.accordion {
  padding: 0;

  div:not(:last-child) {
    //border-bottom: 1px solid rgba(10, 10, 10, 0.1);
  }

  div:last-child .accordion-item-details {
    border-radius: 5px;
  }
}

.accordion-item-trigger,
.accordion-item-details-inner {
  //padding: 0.75rem 1.25rem;
}

.accordion-item-title {
  position: relative;
}

.accordion-item-trigger {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
}

.accordion-item-trigger-icon {
  $size: 8px;
  display: block;
  position: absolute;
  top: 0;
  right: 1.25rem;
  bottom: 0;
  margin: auto;
  width: $size;
  height: $size;
  border-right: 2px solid #363636;
  border-bottom: 2px solid #363636;
  transform: translateY(-$size / 4) rotate(45deg);
  transition: transform 0.2s ease;

  .is-active & {
    transform: translateY($size / 4) rotate(225deg);
  }
}

.accordion-item-details {
  overflow: hidden;
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}
.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
.accordion {
  @apply p-0;

  div:last-child .accordion-item-details {
    @apply rounded-sm;
  }
}

.accordion-item-trigger,
.accordion-item-details-inner {
  //@apply py-3 px-5;
}

.accordion-item-title {
  @apply relative;
}

.accordion-item-trigger-icon {
  $size: 8px;
  right: 1.25rem;
  width: $size;
  height: $size;
  transform: translateY(-$size / 4) rotate(45deg);
  transition: transform 0.2s ease;

  .is-active & {
    transform: translateY($size / 4) rotate(225deg);
  }
}
</style>
