<template>
  <div>
    <div class="flex">
      <span class="font-700 mr-1">{{ $t('evaluate.evaluate_parallel') }}</span>
    </div>
    <template v-if="payloadData.length">
      <div v-for="(evaluateItem, index) in payloadData" :key="`parallel-${index}`">
        <EvaluateDropZone
          v-if="index === 0"
          :accept="['EvaluateApi', 'EvaluateAi', 'EvaluateEmail']"
          :evaluate="evaluate"
          :parent-id="item.id"
          :index="index"
          @evaluate-update="$emit('evaluate-update', $event)"
        />
        <EvaluateItem
          :evaluate="evaluate"
          :selectedEvaluateItemId="selectedEvaluateItemId"
          :item="evaluateItem"
          @update="onEvaluateItemUpdated($event, index)"
          @item-click.stop="onItemClick"
          @evaluate-update="$emit('evaluate-update', $event)"
        />
        <EvaluateDropZone
          :accept="['EvaluateApi', 'EvaluateAi', 'EvaluateEmail']"
          :evaluate="evaluate"
          :parent-id="item.id"
          :index="index + 1"
          evaluate-key="else"
          @evaluate-update="$emit('evaluate-update', $event)"
        />
      </div>
    </template>
    <template v-else>
      <EvaluateDropZone :accept="['EvaluateApi', 'EvaluateAi', 'EvaluateEmail']" :evaluate="evaluate" :parent-id="item.id" :index="0" @evaluate-update="$emit('evaluate-update', $event)" />
    </template>
  </div>
</template>

<script>
import EvaluateDropZone from './EvaluateDropZone';

export default {
  name: 'EvaluateParallel',
  components: { EvaluateDropZone },
  props: {
    item: Object,
    evaluate: Array,
    selectedEvaluateItemId: String,
  },
  data() {
    return {
      payloadData: null,
    };
  },
  computed: {
    payload() {
      return this.item.payload;
    },
  },
  methods: {
    onItemClick($event, evaluateItem) {
      this.$emit('item-click', $event, evaluateItem);
    },
    onEvaluateItemUpdated(evaluateItem, evaluateIndex) {
      this.$set(this.payloadData, evaluateIndex, evaluateItem);
      this.$emit('update', this.payloadData);
    },
  },
  watch: {
    payload: {
      handler() {
        this.payloadData = JSON.parse(JSON.stringify(this.payload));
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
