<template>
  <div>
    <transition name="slide-fade">
      <div v-if="!selectedQuestionId" class="col-start-1 col-end-1 row-start-1 row-end-1 flex flex-col overflow-hidden">
        <div>
          <div class="text-primary font-500 text-22">Search for a question</div>
          <div class="mt-4 mb-7">Type your question or search keyword</div>
          <div class="relative h-10 bg-[#F0EDFF] rounded-full">
            <Icon name="search" class="absolute text-gray-400 w-6 h-6 flex-grow-0 mr-3 top-2 left-2" />
            <input v-model="searchText" type="text" class="w-full bg-transparent h-full outline-0 border-none pl-10" placeholder="Search..." @input="onInput" />
          </div>
          <div class="font-500 mt-4 mb-2">Popular topics:</div>
          <div class="flex overflow-auto pb-2">
            <div
              v-for="(category, index) in categories"
              :key="index"
              class="bg-[#F1F1F1] flex-shrink-0 px-4 py-2 rounded-full text-12 mx-1 select-none cursor-pointer"
              :class="{ '!bg-primary text-white': category.id === filterCategory }"
              @click="selectCategory(category)"
            >
              {{ category.text }}
            </div>
          </div>
        </div>
        <div class="pt-3 flex flex-col overflow-auto pr-2">
          <ul class="flex-grow">
            <li
              v-for="(item, index) in filteredQuestions"
              :key="index"
              class="text-[#655E8E] my-1 px-4 py-1 hover:bg-primary hover:text-off-white rounded-8 cursor-pointer select-none"
              @click="select(item)"
            >
              {{ item.text }}
            </li>
          </ul>
          <div class="text-gray-800 flex px-4 py-2 bg-white mt-3 mb-4 border-gray-200 border-solid border rounded-10 items-center hover:bg-gray-300 select-none cursor-pointer">
            <Icon name="auto_fix_high" class="w-5 h-5 mr-3" />
            <span>Take Product Tour</span>
            <Icon name="arrow_forward" class="w-4 h-4 ml-2" />
          </div>
          <HelpMore @contact-us="$emit('contact-us')" />
        </div>
      </div>
    </transition>
    <transition name="slide-fade-in">
      <div v-if="selectedQuestionId" class="col-start-1 col-end-1 row-start-1 row-end-1 overflow-hidden flex flex-col">
        <div class="text-13 flex cursor-pointer -mt-1 justify-between items-center mb-2 text-primary font-500">
          <div class="flex items-center justify-center" @click="selectedQuestionId = null"><Icon name="chevron_left" class="w-4 h-4 mr-1" /> <span>Back</span></div>
          <a class="flex items-center justify-center" href="/help/4" target="_blank"> <span>Open in new tab</span> <Icon name="get_app" class="w-3 h-3 ml-2" /> </a>
        </div>
        <div class="overflow-auto flex-grow">
          <HelpItem :id="selectedQuestionId" />
          <HelpMore @contact-us="$emit('contact-us')" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import HelpMore from '@/components/help/HelpMore.vue';
import HelpItem from '@/components/help/HelpItem.vue';
import { mapActions } from 'vuex';
import debounce from '@/helpers/debounce';

export default {
  name: 'HelpWrapper',
  components: { HelpItem, HelpMore },
  data() {
    return {
      selectedQuestionId: null,
      searchText: null,
      filterCategory: null,
      filteredQuestions: [],
      categories: [],
    };
  },
  async created() {
    this.categories = await this.fetchHelpPopularCategories();
    this.filteredQuestions = await this.fetchHelpPopularQuestions();
  },
  methods: {
    ...mapActions(['fetchHelpPopularQuestions', 'fetchHelpQuestionsByCategory', 'fetchHelpPopularCategories']),
    // eslint-disable-next-line
    onInputDebounced: debounce(async function () {
      this.filteredQuestions = await this.fetchHelpPopularQuestions();
    }, 300),
    onInput(e) {
      this.onInputDebounced(e.target.value);
    },
    select(item) {
      this.selectedQuestionId = item.id;
    },
    async selectCategory(category) {
      if (this.filterCategory === category.id) {
        this.filterCategory = null;
      } else {
        this.filterCategory = category.id;
      }
      this.filteredQuestions = await this.fetchHelpQuestionsByCategory({ categoryId: this.filterCategory });
    },
  },
};
</script>

<style scoped lang="scss"></style>
