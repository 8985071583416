import Vue from 'vue';

export class TypeWriter {
  constructor(ctx = {}) {
    const { timeout, onMessage = () => {}, onEnd = () => {} } = ctx;
    this.queue = [];
    this.timeout = timeout || 50;
    this.text = '';
    this.writing = false;
    this.onMessage = onMessage;
    this.onEnd = onEnd;
  }

  addWord(word) {
    this.queue.push(word);
  }

  byWord() {
    this.writing = true;
    return new Promise((resolve) => {
      let currentText = '';

      const interval = setInterval(() => {
        const nextWord = this.queue.shift();
        if (nextWord) {
          currentText = nextWord === '.' ? `${currentText}${nextWord}` : `${currentText} ${nextWord}`;
          currentText = currentText.replaceAll(/\*\*(.*?)\*\*/gm, '<b>$1</b>');
          this.text = currentText;
          this.onMessage(currentText);
        } else {
          this.writing = false;
          this.onEnd();
          clearInterval(interval);
          resolve();
        }
      }, this.timeout);
    });
  }
}

function scrollContainerToBottom() {
  const container = document.querySelector('#conversation-container');
  if (container) {
    container.scrollTo({
      top: container.scrollHeight,
      left: 0,
      behavior: 'instant',
    });
  }
}

function setContentVariable(vueInstance, context, varName, currentText) {
  if (vueInstance) {
    if (context) {
      vueInstance.$set(context, varName, currentText.trim());
    } else {
      vueInstance.$set(vueInstance, varName, currentText.trim());
    }
  }

  if (context) {
    Vue.set(context, varName, currentText.trim());
  } else {
    Vue.set(vueInstance, varName, currentText.trim());
  }
}

function typewriter({ content, varName, vueInstance, context = null }) {
  return new Promise((resolve) => {
    setContentVariable(vueInstance, context, varName, content);
    scrollContainerToBottom();
    resolve();
  });
}

function byWord({ content, varName, vueInstance, context = null, timeout = 50 }) {
  return new Promise((resolve) => {
    let currentText = '';
    const myWords = content.split(' ').reverse();

    const interval = setInterval(() => {
      const nextWord = myWords.pop();
      if (nextWord) {
        currentText = `${currentText} ${nextWord}`;
        currentText = currentText.replaceAll(/\*\*(.*?)\*\*/gm, '<b>$1</b>');
        setContentVariable(vueInstance, context, varName, currentText);
        scrollContainerToBottom();
      } else {
        clearInterval(interval);
        resolve();
      }
    }, timeout);
  });
}

function byLetter({ content, varName, vueInstance, context = null, timeout = 10 }) {
  return new Promise((resolve) => {
    let i = 0;
    let isTag;
    let text;

    (function type() {
      if (i === content.length) {
        return resolve();
      }
      text = content.slice(0, ++i);

      if (context) {
        vueInstance.$set(context, varName, text);
      } else {
        vueInstance.$set(vueInstance, varName, text);
      }

      const char = text.slice(-1);
      if (char === '<') {
        isTag = true;
      }
      if (char === '>') {
        isTag = false;
      }

      if (isTag) {
        return type();
      }

      setTimeout(type, timeout || 20);
      return null;
    })();
  });
}

export default typewriter;
export const typeByLetter = byLetter;
export const typeByWord = byWord;
