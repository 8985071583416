<template>
  <div class="bg-white border border-solid border-purple-200 hover:bg-purple-200 rounded-full h-10 px-1 py-1 pr-3 flex items-center cursor-pointer" @click="$emit('click')">
    <img v-if="activeAccount.icon" :src="activeAccount.icon" class="w-7 h-7 mr-4" />
    <span class="pointer-events-none">
      <span :class="{ 'ml-5': !activeAccount.icon }">{{ activeAccount.name }}</span>
    </span>
    <Icon class="pointer-events-none w-4 h-4 ml-4" :name="isPanelOpen ? 'expand_less' : 'expand_more'" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'AccountSelectDropdown',
  props: {
    isPanelOpen: Boolean,
  },
  computed: {
    ...mapState(['availableDataSources']),
    ...mapGetters(['getActiveAccount']),
    activeAccount() {
      if ('account_id' in this.getActiveAccount) {
        return {
          icon: this.availableDataSources[this.getActiveAccount.type].logo || null,
          id: this.getActiveAccount.account_id,
          name: this.getActiveAccount.name,
        };
      }
      return {
        icon: null,
        id: null,
        name: this.$t('accounts_dropdown.select_account'),
      };
    },
  },
};
</script>
