var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"rounded-full focus:outline-none whitespace-nowrap text-13 font-500 truncate flex-shrink-0 select-none",class:{
    'h-7 py-1 px-4': _vm.small,
    'h-8 py-1 px-4': !_vm.small,
    'flex items-center justify-center': _vm.loading || _vm.icon || _vm.trailingIcon,
    'bg-white hover:bg-gray-200': !_vm.disabled && !_vm.primary && !_vm.secondary && !_vm.success && !_vm.outlined && !_vm.tertiary && !_vm.error,
    'bg-primary text-white hover:text-indigo-700 hover:bg-indigo-300': !_vm.disabled && _vm.primary,
    'bg-secondary text-white': !_vm.disabled && _vm.secondary,
    'bg-tertiary text-white hover:opacity-90': !_vm.disabled && _vm.tertiary,
    'bg-green-500 text-white hover:bg-green-600': !_vm.disabled && _vm.success,
    'bg-red-500 text-white hover:bg-red-600 hover:text-white': !_vm.disabled && _vm.error,
    'shadow-analysisCard': !_vm.disabled && _vm.elevated,
    'border border-px border-solid border-gray-200 hover:bg-gray-200': _vm.outlined,
    'bg-gray-200 text-gray-500 cursor-not-allowed': _vm.disabled,
  },on:{"click":function($event){!_vm.disabled && _vm.$emit('click', $event)},"blur":function($event){return _vm.$emit('blur', $event)}}},[(_vm.icon || _vm.loading)?_c('Icon',{staticClass:"w-4 h-4 flex-shrink-0",class:( _obj = {
      'animate-spin': _vm.loading
    }, _obj[_vm.iconClass] = true, _obj['text-gray-500'] =  !_vm.disabled && !_vm.primary && !_vm.secondary && !_vm.tertiary && !_vm.success && !_vm.error && !_vm.outlined, _obj['text-white'] =  !_vm.disabled && _vm.secondary, _obj['text-white'] =  !_vm.disabled && _vm.tertiary, _obj['text-white'] =  !_vm.disabled && _vm.success, _obj['text-white'] =  !_vm.disabled && _vm.error, _obj['mr-2'] =  !_vm.hideTextOnLoading, _obj ),attrs:{"name":_vm.loading ? 'loading_circle' : _vm.icon}}):_vm._e(),_c('span',{staticClass:"text-left",class:( _obj$1 = {}, _obj$1[_vm.textClass] = true, _obj$1 ),staticStyle:{"text-overflow":"inherit","overflow":"inherit"}},[_vm._v(" "+_vm._s(_vm.hideTextOnLoading && _vm.loading ? '' : _vm.text)+" ")]),(_vm.trailingIcon)?_c('Icon',{staticClass:"w-4 h-4 flex-shrink-0",class:( _obj$2 = {
      'animate-spin': _vm.loading
    }, _obj$2[_vm.iconClass] = true, _obj$2['text-gray-500'] =  !_vm.disabled && !_vm.primary && !_vm.secondary && !_vm.tertiary && !_vm.success && !_vm.error && !_vm.outlined, _obj$2['text-white'] =  !_vm.disabled && _vm.secondary, _obj$2['text-white'] =  !_vm.disabled && _vm.tertiary, _obj$2['text-white'] =  !_vm.disabled && _vm.success, _obj$2['text-white'] =  !_vm.disabled && _vm.error, _obj$2['ml-1 -mr-2'] =  !_vm.hideTextOnLoading, _obj$2 ),attrs:{"name":_vm.loading ? 'loading_circle' : _vm.trailingIcon}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }