import Vue from 'vue';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import VTooltip from 'v-tooltip';
import vSelect from '@/components/ui/VSelectWrapper';
import 'vue-select/dist/vue-select.css';
import App from '@/components/App.vue';
import store from '@/plugins/store';
import router from '@/plugins/router';
import modalPlugin from '@/plugins/modal';
import '@/assets/css/tailwind.css';
import '@/assets/css/vue-select.css';
import '@/assets/css/tooltip.css';
import i18n from '@/plugins/i18n';
import PaddleController from '@/helpers/paddle';
import DefaultLayout from '@/components/layouts/Default';
import OutsideClickDirective from '@/directives/outsideClickDirective';
import DefaultNoHeader from '@/components/layouts/DefaultNoHeader';
import LinkButton from '@/components/ui/LinkButton';
import Button from '@/components/ui/Button';
import PillButton from '@/components/ui/PillButton';
import IconToggleButton from '@/components/ui/IconToggleButton';
import Badge from '@/components/ui/Badge';
import Modal from '@/components/ui/Modal';
import FullScreenModal from '@/components/ui/FullScreenModal';
import Avatar from '@/components/ui/Avatar';
import Input from '@/components/ui/Input';
import FormInput from '@/components/ui/FormInput';
import FormTextarea from '@/components/ui/FormTextarea';
import FormPassword from '@/components/ui/FormPassword';
import FormSelect from '@/components/ui/FormSelect';
import Textarea from '@/components/ui/Textarea';
import Toast from '@/components/ui/Toast';
import Icon from '@/components/ui/Icon';
import Card from '@/components/ui/Card';
import Select from '@/components/ui/Select';
import MultiSelect from '@/components/ui/MultiSelect';
import Divider from '@/components/ui/Divider';
import ListCard from '@/components/ui/ListCard';
import Spinner from '@/components/ui/Spinner';
import RadioButton from '@/components/ui/RadioButton';
import Checkbox from '@/components/ui/Checkbox';
import ButtonToggle from '@/components/ui/ButtonToggle';
import CollapseMenu from '@/components/ui/CollapseMenu';
import CollapseMenuBorderless from '@/components/ui/CollapseMenuBorderless';
import CollapseCard from '@/components/ui/CollapseCard';
import ContentCard from '@/components/ui/ContentCard';
import EvaluateItem from '@/components/flow/evaluate/EvaluateItem';
import VariableChip from '@/components/ui/VariableChip';
import VariableSelect from '@/components/ui/VariableSelect';
import Toggle from '@/components/ui/Toggle';

import { setStore } from '@/helpers/api';
import CompactAnalysisCard from '@/components/conversation/CompactAnalysisCard';
import IconButton from '@/components/ui/IconButton';
import DataTable from '@/components/ui/DataTable';

setStore(store);

dayjs.extend(isToday);

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['g', 'r'];

Vue.directive('outside-click', OutsideClickDirective);

Vue.component('v-select', vSelect);
Vue.component('apexchart', window.VueApexCharts);
Vue.component('DefaultLayout', DefaultLayout);
Vue.component('DefaultNoHeaderLayout', DefaultNoHeader);
Vue.component('PillButton', PillButton);
Vue.component('IconToggleButton', IconToggleButton);
Vue.component('Badge', Badge);
Vue.component('Avatar', Avatar);
Vue.component('Input', Input);
Vue.component('FormInput', FormInput);
Vue.component('FormTextarea', FormTextarea);
Vue.component('FormPassword', FormPassword);
Vue.component('FormSelect', FormSelect);
Vue.component('Textarea', Textarea);
Vue.component('Button', Button);
Vue.component('ButtonToggle', ButtonToggle);
Vue.component('Select', Select);
Vue.component('MultiSelect', MultiSelect);
Vue.component('LinkButton', LinkButton);
Vue.component('Modal', Modal);
Vue.component('FullScreenModal', FullScreenModal);
Vue.component('Toast', Toast);
Vue.component('Icon', Icon);
Vue.component('IconButton', IconButton);
Vue.component('Card', Card);
Vue.component('Divider', Divider);
Vue.component('ListCard', ListCard);
Vue.component('Spinner', Spinner);
Vue.component('RadioButton', RadioButton);
Vue.component('Checkbox', Checkbox);
Vue.component('CollapseMenu', CollapseMenu);
Vue.component('CollapseMenuBorderless', CollapseMenuBorderless);
Vue.component('CollapseCard', CollapseCard);
Vue.component('ContentCard', ContentCard);
Vue.component('CompactAnalysisCard', CompactAnalysisCard);
Vue.component('EvaluateItem', EvaluateItem);
Vue.component('VariableChip', VariableChip);
Vue.component('VariableSelect', VariableSelect);
Vue.component('DataTable', DataTable);
Vue.component('Toggle', Toggle);

Vue.use(VTooltip);

PaddleController.setup();

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(modalPlugin, { appInstance: app });
