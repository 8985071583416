<template>
  <DefaultLayout showTopBar showAccounts v-if="bootstrapped">
    <div class="relative h-full flex flex-col overflow-hidden">
      <div v-if="getSubscriptionIsExpired">
        <Alert class="mr-4">Your subscription for one or more data sources has expired, please upgrade to continue viewing these.</Alert>
      </div>
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <keep-alive>
            <component :key="$route.path" :is="Component" />
          </keep-alive>
        </transition>
      </router-view>
      <div v-if="showSearchTitle" class="pl-6 w-full pb-1 max-w-1000 mx-auto pt-2 font-500 text-20 text-dark-text">{{ $t('dashboard.ask_question') }}</div>
      <Search />
    </div>
  </DefaultLayout>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import Search from '@/components/header/Search';
import Alert from '@/components/ui/Alert';

export default {
  name: 'Discovery',
  components: { Alert, Search },
  created() {
    this.SET_CONVERSATION_HELP_MINIMIZE(true);
  },
  computed: {
    ...mapState(['user', 'conversation', 'bootstrapped']),
    ...mapGetters(['getSubscriptionIsExpired']),
    userName() {
      return this.user?.name?.split(' ')?.[0] || '';
    },
    conversationResponseWaiting() {
      return Object.values(this.conversation).some((c) => c.loading === true);
    },
    showSearchTitle() {
      return this.$route.name === 'dashboard';
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      const scrollContainer = document.getElementById('conversation-container');
      scrollContainer?.scrollBy({ left: 0, top: scrollContainer.scrollHeight });
    });
  },
  methods: {
    ...mapMutations(['SET_CONVERSATION_HELP_MINIMIZE']),
  },
  watch: {
    conversationResponseWaiting() {
      this.$nextTick().then(() => {
        const scrollContainer = document.getElementById('conversation-container');
        scrollContainer?.scrollBy({ left: 0, top: scrollContainer.scrollHeight, behavior: 'smooth' });
      });
    },
  },
};
</script>

<style>
.fade-enter-active {
  animation: fade 0.25s;
}

.fade-leave-active {
  animation: fade 0.25s reverse;
}

@keyframes fade {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}
</style>
