var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid grid-flow-col auto-cols-fr z-0"},_vm._l((_vm.items),function(item,key){
var _obj;
return _c('button',{key:item,staticClass:"box-border transition duration-300 ease-in-out text-indigo-700 font-700 text-center border border-gray-200 focus:outline-none",class:( _obj = {
      'rounded-tl-full': key === 0,
      'rounded-bl-full': key === 0,
      'rounded-tr-full': key + 1 === _vm.items.length,
      'rounded-br-full': key + 1 === _vm.items.length,
      'border-l-0': key !== 0,
      'border-r-0': key + 1 !== _vm.items.length,
      'bg-purple-200': _vm.value === item,
      'z-1': _vm.value === item
    }, _obj[_vm.height] = true, _obj[_vm.padding] = true, _obj[_vm.fontSize] = true, _obj ),on:{"click":function($event){$event.stopPropagation();return _vm.$emit('input', item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }