<template>
  <div class="h-8 flex items-center justify-center bg-[#F0EDFF] hover:bg-tertiary text-primary hover:text-white px-2 rounded-full cursor-pointer" @click="$emit('click', $event)">
    <Icon name="generating_tokens" class="w-6 h-6 mr-1" />
    <span class="font-500 mx-2 mb-0.5" :style="{ fontSize: quotaText === 'Infinity' ? '21px' : '14px' }">{{ quotaText === 'Infinity' ? '∞' : quotaText }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Quota',
  computed: {
    ...mapState(['quota']),
    quotaText() {
      return this.quota;
    },
  },
};
</script>

<style scoped lang="scss"></style>
