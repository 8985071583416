<template>
  <div>
    <template v-for="(value, index) in expressions">
      <VariableChip v-if="isVariable(value)" :name="removeCurly(value)" :key="index" :index="5" />
      <span v-else :key="index">{{ value }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ExpressionRenderer',
  props: {
    expression: String,
  },
  data() {
    return {
      expressions: [],
    };
  },
  methods: {
    isVariable(str) {
      return str.startsWith('{') && str.endsWith('}');
    },
    removeCurly(str) {
      return str.substring(1).slice(0, -1);
    },
    parseExpression(expression) {
      const regex = /(\{)([^}]+)\}/gim;
      const matches = expression.match(regex);

      const expressionArray = [];
      if (matches && matches.length) {
        let exp = expression;
        matches.forEach((x, i) => {
          const split = exp.split(x);
          const left = split[0];
          exp = split[1];
          expressionArray.push(left);
          expressionArray.push(x);
          if (i + 1 === matches.length) {
            expressionArray.push(exp);
          }
        });
      } else {
        expressionArray.push(expression);
      }
      return expressionArray.filter((x) => x);
    },
  },
  watch: {
    expression: {
      handler() {
        this.expressions = this.parseExpression(this.expression);
      },
      immediate: true,
    },
  },
};
</script>
