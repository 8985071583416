var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FadeTransition',[_c('div',{staticClass:"fixed z-100 bottom-0 top-0 inset-x-0 inset-0 flex items-center justify-center min-w-320 backdrop-blur-sm backdrop-filter",class:{ 'px-4 pb-4': !_vm.fullbelowsm && !_vm.sheetbelowsm, 'pointer-events-none': _vm.primaryButtonLoading }},[_c('div',{staticClass:"fixed inset-0"},[_c('div',{staticClass:"absolute inset-0 bg-gray-400 opacity-75"})]),_c('FadeTransition',[_c('div',{staticClass:"bg-white overflow-hidden transform transition-all duration-300 ease-in-out",class:( _obj = {
          'h-full sm:h-auto sm:rounded-20 sm:shadow-xl w-full': _vm.fullbelowsm,
          'rounded-20 shadow-xl max-h-screen': !_vm.fullbelowsm && !_vm.sheetbelowsm,
          'mt-auto sm:my-auto max-sm:w-full rounded-t-20 sm:rounded-t-26 sm:rounded-b-26 shadow-xl max-h-screen': _vm.sheetbelowsm,
          'max-sm:translate-y-full': !_vm.mounted && _vm.sheetbelowsm
        }, _obj[_vm.containerClass] = true, _obj ),attrs:{"role":"dialog","aria-modal":"true","aria-labelledby":"modal-headline"}},[_c('div',{staticClass:"bg-white w-full overflow-hidden flex flex-col",class:{ 'h-full sm:h-auto': _vm.fullbelowsm, 'max-h-screen': !_vm.fullbelowsm }},[(_vm.title || _vm.closeButton)?[_c('div',{staticClass:"pt-10 px-10 flex items-center justify-between mb-2"},[(_vm.title)?_c('div',{staticClass:"relative font-600 text-20"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.closeButton)?_c('div',{staticClass:"rounded-full w-6 h-6 flex justify-center items-center cursor-pointer ml-auto",class:{ 'right-5': _vm.title, 'top-5': _vm.title, 'right-3': !_vm.title, 'top-1': !_vm.title },on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}},[_c('Icon',{staticClass:"w-5 h-5",attrs:{"name":"clear"}})],1):_vm._e()])]:_vm._e(),_c('div',{staticClass:"overflow-auto flex-grow",class:( _obj$1 = {}, _obj$1[_vm.overflowContainerClass] = true, _obj$1 )},[_vm._t("default")],2),(_vm.footer)?[_c('Divider',{staticClass:"mt-3",attrs:{"dense":""}}),_c('div',{staticClass:"w-full"},[_vm._t("footer")],2)]:(_vm.primaryButton || _vm.secondaryButton)?[_c('Divider',{staticClass:"mt-5",attrs:{"dense":""}}),_c('div',{staticClass:"w-full flex py-4 px-10"},[(_vm.secondaryButton)?_c('PillButton',{attrs:{"text":_vm.secondaryButton,"disabled":_vm.primaryButtonLoading || _vm.secondaryButtonDisabled,"outlined":""},on:{"click":function($event){return _vm.$emit('secondary', $event)}}}):_vm._e(),(_vm.primaryButton)?_c('PillButton',{staticClass:"ml-auto",attrs:{"primary":"","loading":_vm.primaryButtonLoading,"disabled":_vm.primaryButtonLoading || _vm.primaryButtonDisabled,"text":_vm.primaryButton,"outlined":""},on:{"click":function($event){return _vm.$emit('primary', $event)}}}):_vm._e()],1)]:_vm._e()],2)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }