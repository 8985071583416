<template>
  <div>
    <Select :value="activeItem" :items="getProjects" class="ml-2 sm:ml-0 min-w-200 flex-grow" :reducer="(option) => option.value" @input="onChange"></Select>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NoCodeHeader',
  data() {
    return {
      activeItem: null,
    };
  },
  computed: {
    ...mapState(['projects']),
    getProjects() {
      return Object.values(this.projects).map((project) => ({ text: project.name, value: project.project_id }));
    },
  },
  created() {
    if (this.$route.params.id) {
      this.activeItem = this.$route.params.id;
    }
  },
  methods: {
    async onChange(id) {
      await this.$router.push({ name: this.$route.name, params: { id } });
      this.activeItem = id;
    },
  },
};
</script>
