<template>
  <DefaultLayout showTopBar>
    <template #header>
      <div class="text-17 flex justify-center items-center font-600 text-indigo-700">
        <span class="text-20">{{ $t('collections.title') }}</span>
        <PillButton class="ml-5 hidden sm:flex" icon="plus" :text="$t('add')" primary @click="openCreateCollectionModal" />
        <IconButton class="ml-5 inline sm:hidden" icon="plus" dense primary @click="openCreateCollectionModal" />
      </div>
    </template>
    <div class="w-full h-full flex flex-col overflow-hidden">
      <div class="flex-grow overflow-auto">
        <div class="w-full p-3 sm:p-5 grid gap-4 grid-cols-collection-mobile sm:grid-cols-collection">
          <ListCard class="h-140 py-2 bg-white rounded-20 cursor-pointer" v-for="collection in collections" :key="collection.id" @click="goToCollection(collection)">
            <div class="flex flex-col w-full h-full">
              <div class="flex py-3 pl-4 pr-2 items-center">
                <div class="flex-grow text-16 font-500 text-gray-900">{{ collection.name }}</div>
                <IconButton icon="edit" @click.native.stop="openEditCollectionModal(collection)" />
                <IconButton icon="delete" class="text-primary" @click.native.stop="openDeleteCollectionModal(collection)" />
              </div>
              <Divider dense />
              <div class="flex p-5 items-center">
                <template v-if="collection.length === 0">
                  <div class="text-gray-500">{{ $t('collections.zero_card') }}</div>
                </template>
                <template v-else>
                  <div v-for="(question, dataSource) in getCollectionInfoByDataSource(collection)" :key="dataSource" class="flex mr-5">
                    <img :src="availableDataSources[dataSource].logo" class="w-5 h-5 mr-2" />
                    <div class="flex-grow text-13">{{ question.length }}</div>
                  </div>
                  <div v-if="collection.digest" class="ml-auto text-13 text-gray-500 capitalize">{{ $t(`collections.${collection.digest}`) }}</div>
                </template>
              </div>
            </div>
          </ListCard>
        </div>
      </div>
    </div>
    <Modal
      v-if="showCreateCollectionModal"
      sheetbelowsm
      :title="$t('collections.create2')"
      :primary-button="$t('save')"
      :primary-button-disabled="!collectionName.trim() || loading"
      :primary-button-loading="loading"
      :secondary-button="$t('cancel')"
      :secondary-button-disabled="loading"
      @primary="onSaveClicked"
      @secondary="showCreateCollectionModal = false"
    >
      <div class="w-full sm:w-700">
        <div class="w-full flex flex-col justify-start mt-3 py-7 px-10">
          <label class="block text-13 mb-1">{{ $t('collections.name') }}</label>
          <Input ref="collectionNameEl" :readonly="loading" v-model="collectionName" spellcheck="false" class="w-full" />
          <p v-if="createCollectionNameError" class="text-red-600 text-13 text-left pt-1 italic">{{ createCollectionNameError }}</p>
        </div>
        <div class="w-full flex flex-col justify-start pb-3 px-10">
          <label class="block text-13 mb-1">{{ $t('collections.get_email_notifications') }}</label>
          <v-select v-model="digest" :options="digestItems" :reduce="(option) => option.value" appendToBody :readonly="loading" class="w-full" />
        </div>
      </div>
    </Modal>
  </DefaultLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { COLLECTION_DIGEST } from '@/constants';
import DeleteModal from '@/components/shared-modals/DeleteModal';

export default {
  name: 'Collections',
  data() {
    return {
      collectionName: '',
      editCollectionId: '',
      createCollectionNameError: '',
      showCreateCollectionModal: false,
      loading: false,
      COLLECTION_DIGEST,
      digestItems: [
        { label: this.$t(`collections.${COLLECTION_DIGEST.daily}`), value: COLLECTION_DIGEST.daily },
        { label: this.$t(`collections.${COLLECTION_DIGEST.weekly}`), value: COLLECTION_DIGEST.weekly },
        { label: this.$t(`collections.${COLLECTION_DIGEST.monthly}`), value: COLLECTION_DIGEST.monthly },
      ],
      digest: null,
    };
  },
  computed: {
    ...mapState(['collections', 'availableDataSources']),
  },
  created() {
    if (this.$route.hash === '#new') {
      this.openCreateCollectionModal();
    }
  },
  methods: {
    ...mapActions(['createCollection', 'updateCollection', 'deleteCollection']),
    groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
      }, {});
    },
    getCollectionInfoByDataSource(collection) {
      return this.groupBy(collection.questions, 'type');
    },
    goToCollection(collection) {
      this.$router.push({ name: 'collection', params: { id: collection.id } });
    },
    async openCreateCollectionModal() {
      this.collectionName = '';
      this.editCollectionId = '';
      this.createCollectionNameError = '';
      this.showCreateCollectionModal = true;
      await this.$nextTick();
      this.$refs.collectionNameEl.$el.focus();
    },
    async openEditCollectionModal(collection) {
      this.collectionName = collection.name;
      this.digest = collection.digest;
      this.editCollectionId = collection.id;
      this.createCollectionNameError = '';
      this.showCreateCollectionModal = true;
      await this.$nextTick();
      this.$refs.collectionNameEl.$el.focus();
    },
    openDeleteCollectionModal(collection) {
      this.$showModal(
        DeleteModal,
        {
          subtitle: this.$t('collections.remove_collection_question', { collectionName: collection.name }),
          info: null,
        },
        { confirm: () => this.onDeleteClicked(collection) },
      );
    },
    async onSaveClicked() {
      if (this.collectionName && this.collectionName.trim() !== '') {
        this.loading = true;
        if (!this.editCollectionId) {
          await this.createCollection({ name: this.collectionName, digest: this.digest });
        } else {
          await this.updateCollection({ collection_id: this.editCollectionId, name: this.collectionName, digest: this.digest });
        }
        this.loading = false;
        this.showCreateCollectionModal = false;
      } else {
        this.createCollectionNameError = 'Collection name is required!';
      }
    },
    async onDeleteClicked(collection) {
      this.loading = true;
      await this.deleteCollection({ collection_id: collection.id });
      this.loading = false;
    },
  },
};
</script>
