<template>
  <Modal v-if="showExportModal" containerClass="w-[98vw] md:w-[95vw] max-w-[1500px]" close-button sheetbelowsm @close="close" ref="exportModal" @after-enter="onModalMount">
    <div :style="{ transform: `scale(${exportZoom})`, 'transform-origin': 'left top', maxHeight: exportHeight }" class="px-0 pt-0 overflow-hidden">
      <div ref="exportCard" :style="{ width: `${this.exportModalWidth}px` }" class="bg-white max-sm:h-450 h-500 border-b border-gray-200">
        <AnalysisCard :datasource-type="datasourceType" :style="{ width: `${this.exportModalWidth}px` }" :export-chart-type="exportChartType" export-mode :result="result" />
      </div>
    </div>

    <div class="flex justify-center items-center mb-2 flex-wrap py-3">
      <div class="pl-2 pb-1 text-center w-full font-700 text-13">{{ $t('conversation.save_as') }}</div>

      <PillButton icon="get_app" class="hidden sm:flex mx-2" :text="$t('conversation.export_to_pdf')" @click="exportPdf" />
      <PillButton icon="get_app" class="hidden sm:flex mx-2" :text="$t('conversation.convert_to_png')" @click="exportPng" />
      <PillButton icon="get_app" class="hidden sm:flex mx-2" :text="$t('conversation.export_to_csv')" @click="exportCsv" />
      <PillButton v-if="false" icon="get_app" class="hidden sm:flex mx-2" :text="$t('conversation.email_me')" @click="sendEmail" />

      <PillButton icon="get_app" class="inline sm:hidden mx-1" text="PDF" @click="exportPdf" />
      <PillButton icon="get_app" class="inline sm:hidden mx-1" text="PNG" @click="exportPng" />
      <PillButton icon="get_app" class="inline sm:hidden mx-1" text="CSV" @click="exportCsv" />
      <PillButton v-if="false" icon="get_app" class="inline sm:hidden mx-1" :text="$t('conversation.email_me')" @click="sendEmail" />
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import AnalysisCard from '@/components/conversation/AnalysisCard';
import html2pdf from 'html2pdf.js';
import * as htmlToImage from 'html-to-image';

export default {
  name: 'AnalysisCardExportModal',
  components: { AnalysisCard },
  props: {
    showExportModal: Boolean,
    exportChartType: String,
    insights: Array,
    result: Object,
    datasourceType: String,
  },
  data() {
    return {
      exportZoom: 1,
      exportModalWidth: '70%',
      exportHeight: '',
      orientationTimeout: null,
    };
  },
  mounted() {
    window.addEventListener('orientationchange', this.onOrientationChange);
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.onOrientationChange);
    if (this.orientationTimeout) {
      clearTimeout(this.orientationTimeout);
    }
  },
  methods: {
    ...mapActions(['exportToCsv', 'emailCardToUser']),
    onOrientationChange() {
      if (this.showExportModal) {
        if (this.orientationTimeout) {
          clearTimeout(this.orientationTimeout);
        }
        this.orientationTimeout = setTimeout(() => {
          this.onModalMount();
        }, 300);
      }
    },
    onModalMount() {
      // const scrollContainer = document.getElementById('app');
      // const { scrollTop } = scrollContainer;
      // scrollContainer.scrollTop = 0;
      // this.exportZoom = 1;
      // const parentHeight = this.$refs.exportCard.offsetHeight;
      // const parentWidth = this.$refs.exportCard.parentNode.parentNode.offsetWidth;
      // console.log(parentWidth);
      // console.log(this.exportModalWidth);
      // console.log("parentHeight", parentHeight);
      // this.$nextTick().then(() => {
      // this.exportZoom = parentWidth / this.exportModalWidth;
      // this.exportHeight = `${parentHeight * this.exportZoom}px`;
      // scrollContainer.scrollTop = scrollTop;
      // });
    },
    exportPdf() {
      htmlToImage.toCanvas(this.$refs.exportCard).then((canvas) => {
        const opt = { margin: [0.5, 2, 0.5, 2], image: { type: 'jpeg', quality: 0.98 }, canvas, jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' } };
        html2pdf().set(opt).from(this.$refs.exportCard).save();
        this.$emit('close');
        this.$modal?.close();
      });
    },
    exportPng() {
      htmlToImage.toPng(this.$refs.exportCard).then((dataUrl) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = dataUrl;
        a.target = '_blank';
        a.download = 'export.png';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
        }, 100);
        this.$emit('close');
        this.$modal?.close();
      });
    },
    exportCsv() {
      this.exportToCsv(this.result.table);
      this.$emit('close');
      this.$modal?.close();
    },
    sendEmail() {
      this.emailCardToUser({ ...this.result, selected_chart: this.exportChartType });
      this.$emit('close');
      this.$modal?.close();
    },
    close() {
      this.$emit('close');
      this.$modal?.close();
    },
  },
  watch: {
    showExportModal() {
      this.exportHeight = '';
    },
  },
};
</script>
