const ModalPlugin = {
  // eslint-disable-next-line
  install: function (Vue, options) {
    const { appInstance } = options;

    const modalContainer = document.createElement('div');
    modalContainer.id = 'modal-wrapper';
    document.getElementsByTagName('body')[0].appendChild(modalContainer);

    if (!appInstance) {
      console.error('appInstance parameter must be required!'); // eslint-disable-line
      return;
    }
    // eslint-disable-next-line func-names
    Vue.prototype.$showModal = function (Component, props, listeners) {
      const modalWrapper = document.createElement('div');
      modalContainer.appendChild(modalWrapper);
      const instance = new Vue({ name: 'MModal', parent: appInstance, render: (h) => h(Component, { props, on: listeners }) }).$mount(modalWrapper);

      const modalObject = {
        close() {
          instance.$destroy();
          instance.$el.remove();
        },
        delayedClose() {
          setTimeout(() => {
            instance?.$destroy();
            instance.$el.remove();
          }, 1000);
        },
      };

      if (instance.$children[0]) {
        instance.$children[0].$modal = modalObject;
      }

      return modalObject;
    };
  },
};

export default ModalPlugin;
