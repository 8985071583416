<template>
  <Modal
    sheetbelowsm
    :title="project ? $t('projects.edit') : $t('projects.create')"
    :primary-button="$t('save')"
    :primary-button-disabled="!name.trim() || loading"
    :primary-button-loading="loading"
    :secondary-button-disabled="loading"
    :secondary-button="$t('cancel')"
    close-button
    @primary="onSaveClick"
    @secondary="onCancelClick"
    @close="onCancelClick"
  >
    <div class="w-full sm:w-700">
      <div class="w-full flex flex-col justify-start mt-3 py-7 px-10">
        <label class="block text-13 mb-1">{{ $t('projects.name') }}</label>
        <Input ref="name" :readonly="loading" v-model="name" spellcheck="false" class="w-full" />
        <p v-if="nameError" class="text-red-600 text-13 text-left pt-1 italic">{{ nameError }}</p>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CreateProjectModal',
  props: {
    project: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      name: '',
      nameError: '',
    };
  },
  created() {
    if (this.project) {
      this.name = this.project.name;
    }
  },
  mounted() {
    this.$refs.name.$el.focus();
  },
  methods: {
    ...mapActions(['createProject', 'updateProject']),
    async onSaveClick() {
      this.loading = true;
      if (this.project) {
        const { project_id, tenants, authentication, cors } = this.project;
        await this.updateProject({ name: this.name.trim(), project_id, tenants, authentication, cors });
      } else {
        await this.createProject({ name: this.name.trim(), tenants: {}, authentication: {}, cors: {} });
      }
      this.loading = false;
      this.$modal.close();
    },
    onCancelClick() {
      this.$modal.close();
    },
  },
};
</script>

<style scoped></style>
