<template>
  <div class="relative" :tabindex="tabindex" @blur="open = false">
    <span class="inline-block w-full rounded-md">
      <!-- eslint-disable -->
      <button
        v-if="!text"
        @click="open = !open"
        @blur="onBlur"
        :class="{ 'h-10': !dense, 'h-8': dense }"
        class="
          cursor-default
          shadow-analysisCard
          relative
          w-full
          rounded-full
          bg-white
          pl-5
          pr-8
          py-1
          text-left
          focus:outline-none focus:shadow-outline-blue focus:border-blue-300
          transition
          ease-in-out
          duration-150
          text-sm
          leading-5
        "
      >
        <!-- eslint-enable -->
        <div class="flex items-center space-x-3">
          <span class="block truncate"> {{ selectedText }} </span>
        </div>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
            <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </button>
      <PillButton v-else :text="selectedText" class="flex flex-row-reverse" small icon="chevron_right" icon-class="transform rotate-90 w-4 h-4 !mr-0 ml-1" @click="open = !open" @blur="onBlur" />
    </span>

    <div class="absolute z-2 mt-1 w-full rounded-md bg-white shadow-mini-card max-h-300 overflow-y-auto min-w-[120px]" v-if="open">
      <ul tabindex="-1" class="max-h-56 rounded-md py-1 overflow-auto focus:outline-none text-sm leading-5">
        <!--
          Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
        -->
        <li class="text-gray-900 select-none relative py-2 pl-5 pr-9 cursor-pointer hover:text-white hover:bg-tertiary" v-for="(option, i) of options" :key="i" @click="onClicked(option)">
          <div class="flex items-center space-x-3">
            <span class="font-400 block truncate" :class="{ 'font-700': option.value === selected }"> {{ option.text }} </span>
          </div>

          <!--
            Highlighted: "text-white", Not Highlighted: "text-indigo-600"
          -->
          <span class="absolute inset-y-0 right-0 flex items-center pr-2" v-if="option.value === selected">
            <Icon name="check" class="h-5 w-5" />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    items: Array,
    value: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    text: Boolean,
    dense: Boolean,
  },
  data() {
    return {
      open: false,
      selectedText: '',
      selected: null,
      options: [],
    };
  },
  methods: {
    onBlur() {
      setTimeout(() => {
        this.open = false;
      }, 200);
    },
    onClicked(option) {
      this.$emit('input', option.value);
      this.$emit('click', option.value);
    },
    setSelectedItem() {
      if (this.selected === this.value) {
        return;
      }
      if (this.options) {
        if (this.options.length > 0) {
          const option = this.options.find((item) => item.value === this.value);
          if (option) {
            this.selectedText = option.text;
            this.selected = option.value;
            this.$emit('input', this.selected);
            return;
          }
          this.selectedText = this.options[0].text;
          this.selected = this.options[0].value;
          this.$emit('input', this.selected);
          return;
        }
      }
      this.selectedText = '';
      this.selected = null;
      this.$emit('input', this.selected);
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.length) {
          if (typeof newValue[0] === 'string') {
            this.options = newValue.map((item) => {
              return { value: item, text: item };
            });
          } else if (typeof newValue[0] === 'object') {
            this.options = newValue.map((item) => {
              return { value: item.value, text: item.text };
            });
          } else {
            this.options = [];
          }
        } else {
          this.options = [];
        }
        this.setSelectedItem();
      },
    },
    value() {
      this.setSelectedItem();
    },
  },
};
</script>
