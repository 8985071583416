<template>
  <div
    class="navigation-item w-full flex items-center justify-center overflow-hidden cursor-pointer relative select-none"
    :class="{ 'rounded-t-12': selected }"
    :style="{ backgroundColor: selected ? '#3F2E9B' : '' }"
    v-on="$listeners"
  >
    <div class="w-14 h-14 flex justify-center items-center flex-shrink-0">
      <Icon :name="icon" class="w-6 h-6 text-white flex items-center justify-center flex-shrink-0" :class="{ 'opacity-70': !selected }" />
    </div>
    <span v-if="text" class="text-white text-18">{{ text }}</span>
    <div v-if="selected" class="selected-indicator absolute w-full h-2 bg-white rounded-t-6" style="bottom: -5px"></div>
  </div>
</template>

<script>
export default {
  name: 'BottomNavigationItem',
  props: {
    text: String,
    icon: String,
    selected: Boolean,
  },
};
</script>
<style lang="scss"></style>
