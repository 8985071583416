import Vue from 'vue';
import VueRouter from 'vue-router';
import SideLayout from '@/components/layouts/Side';
import Login from '@/components/views/Login';
import Conversation from '@/components/views/Conversation.vue';
import Settings from '@/components/views/user/Settings.vue';
import Security from '@/components/views/user/Security.vue';
import Subscription from '@/components/views/user/Subscription';
import AuthCallback from '@/components/views/AuthCallback';
import Onboarding from '@/components/views/Onboarding';
import Collections from '@/components/views/Collections';
import Projects from '@/components/views/no-code/Projects';
import { FEATURES } from '@/constants';
import Help from '@/components/views/Help.vue';
import Dashboard from '@/components/views/Dashboard.vue';
import Discovery from '@/components/views/Discovery.vue';

/* Resolve `NavigationDuplicated` bug */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      component: Discovery,
      meta: { requiresAuth: true },
      children: [
        {
          path: '/',
          component: Dashboard,
          name: 'dashboard',
          meta: { requiresAuth: true },
          // redirect: '/conversation',
        },
        {
          path: '/conversation',
          name: 'conversation',
          component: Conversation,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/help/:id',
      name: 'help',
      component: Help,
      meta: { requiresAuth: true },
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: Onboarding,
      meta: { requiresAuth: true },
    },
    {
      path: '/collections',
      name: 'collections',
      component: Collections,
      meta: { requiresAuth: true },
    },
    {
      path: '/collection/:id/:questionId?',
      name: 'collection',
      component: () => import(/* webpackChunkName: "collection" */ '@/components/views/Collection'),
      meta: { requiresAuth: true },
    },
    {
      path: '/user',
      component: SideLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'subscriptions',
          name: 'subscriptions',
          component: Subscription,
          meta: { requiresAuth: true },
        },
        {
          path: 'settings',
          name: 'settings',
          component: Settings,
          meta: { requiresAuth: true },
        },
        {
          path: 'security',
          name: 'security',
          component: Security,
          meta: { requiresAuth: true },
        },
        {
          path: 'datasources',
          name: 'datasources',
          component: () => import(/* webpackChunkName: "datasources" */ '@/components/views/user/Datasources.vue'),
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/no-code',
      name: 'no-code',
      component: Projects,
      meta: { requiresAuth: true },
    },
    {
      path: '/no-code/:id',
      name: 'no-code-parent',
      component: SideLayout,
      meta: { requiresAuth: true, header: 'no_code' },
      children: [
        {
          path: '/no-code/:id/endpoints',
          name: 'no-code-endpoints',
          component: () => import(/* webpackChunkName: "no-code-endpoints" */ '@/components/views/no-code/Endpoints'),
        },
        {
          path: '/no-code/:id/variables',
          name: 'no-code-variables',
          component: () => import(/* webpackChunkName: "no-code-variables" */ '@/components/views/no-code/Variables'),
        },
        {
          path: '/no-code/:id/secrets',
          name: 'no-code-secrets',
          component: () => import(/* webpackChunkName: "no-code-secrets" */ '@/components/views/no-code/Secrets'),
        },
        {
          path: '/no-code/:id/iam',
          name: 'no-code-iam',
          component: () => import(/* webpackChunkName: "no-code-iam" */ '@/components/views/no-code/NoCodeIAM'),
        },
        {
          path: '/no-code/:id/email-templates',
          name: 'no-code-email-templates',
          component: () => import(/* webpackChunkName: "no-code-email-templates" */ '@/components/views/no-code/EmailTemplates'),
        },
        {
          path: '/no-code/:id/authentication',
          name: 'no-code-authentication',
          component: () => import(/* webpackChunkName: "no-code-authentication" */ '@/components/views/no-code/Authentication'),
        },
        {
          path: '/no-code/:id/database',
          name: 'no-code-database',
          component: () => import(/* webpackChunkName: "no-code-database" */ '@/components/views/no-code/Database'),
        },
      ],
    },
    {
      path: '/no-code/:id/flow/:endpoint',
      name: 'no-code-flow',
      component: () => import(/* webpackChunkName: "no-code-flow" */ '@/components/views/no-code/NoCodeFlow'),
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import(/* webpackChunkName: "admin" */ '@/components/views/admin/AdminDashboard'),
      meta: { requiresAuth: true, feature: FEATURES.ADMIN_DASHBOARD, header: 'datasources' },
    },
    {
      path: '/admin',
      component: SideLayout,
      meta: { requiresAuth: true, header: 'datasources' },
      children: [
        {
          path: 'publishing',
          name: 'publishing-admin',
          component: () => import(/* webpackChunkName: "publishing-admin" */ '@/components/views/admin/Publish'),
        },
        {
          path: 'conversations',
          name: 'conversations-admin',
          component: () => import(/* webpackChunkName: "conversations-admin" */ '@/components/views/admin/Conversations'),
        },
        {
          path: 'flows',
          name: 'flows-admin',
          component: () => import(/* webpackChunkName: "flows-admin" */ '@/components/views/admin/Flows'),
        },
        {
          path: 'variables',
          name: 'variables-admin',
          component: () => import(/* webpackChunkName: "variables-admin" */ '@/components/views/admin/Variables'),
        },
        {
          path: 'data-types',
          name: 'data-types-admin',
          component: () => import(/* webpackChunkName: "data-types-admin" */ '@/components/views/admin/DataTypes'),
        },
        {
          path: 'fine-tune',
          name: 'fine-tune-admin',
          component: () => import(/* webpackChunkName: "fine-tune-admin" */ '@/components/views/admin/FineTune.vue'),
        },
        {
          path: 'suggestions',
          name: 'suggestions-admin',
          component: () => import(/* webpackChunkName: "suggestions-admin" */ '@/components/views/admin/Suggestions.vue'),
        },
        {
          path: 'models',
          name: 'models-admin',
          component: () => import(/* webpackChunkName: "models-admin" */ '@/components/views/admin/Models.vue'),
        },
      ],
    },
    {
      path: '/iam',
      name: 'iam',
      component: SideLayout,
      meta: { requiresAuth: true, header: 'iam' },
      children: [
        {
          path: 'user-management',
          name: 'iam-user-management',
          component: () => import(/* webpackChunkName: "iam-users" */ '@/components/views/iam/Users'),
        },
      ],
    },
    {
      path: '/admin/conversation/:intent',
      name: 'admin-conversation',
      component: () => import(/* webpackChunkName: "flow" */ '@/components/views/Flow'),
      meta: { requiresAuth: true },
    },
    {
      path: '/admin/collection/:intent',
      name: 'admin-collection',
      component: () => import(/* webpackChunkName: "admin-colllection" */ '@/components/views/admin/CollectionFlow'),
      meta: { requiresAuth: true },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/passwordreset',
      name: 'password-reset',
      component: Login,
    },
    {
      path: '/register',
      name: 'register',
      component: Login,
    },
    {
      path: '/authcallback',
      name: 'authcallback',
      component: AuthCallback,
    },
    {
      path: '*',
      name: '404',
      redirect: '/login',
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.fullPath && to.query.redirect && to.path !== '/authcallback') {
    next(to.query.redirect);
    return;
  }
  if (!router.app || !router.app.$store) {
    await Vue.nextTick();
  }
  if (router.app) {
    const store = router.app.$store;
    if (store.state.bootstrapped && to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.state.user || !store.state.user.email) {
        next({ path: '/login', params: { nextUrl: to.fullPath } });
        return;
      }
      const { users } = to.meta;
      if (users && users.length && !users.includes(store.state.user.email)) {
        // email not authorised so redirect to home page
        next('/');
        return;
      }

      if (to.matched.some((record) => record.meta.feature && !store.state.features.includes(record.meta.feature))) {
        next({ path: '/' });
        return;
      }
    }
  }

  next();
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});

export default router;
