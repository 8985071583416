<template>
  <div class="bg-off-white shadow-card rounded-10 p-2 lg:p-8 cursor-pointer max-md:mt-5" :class="{ 'outline outline-2 outline-primary bg-white': selected }" @click="$emit('click', $event)">
    <div class="flex flex-col items-center justify-center max-md:flex-row mt-3">
      <img :src="logo" class="w-10 md:w-15" alt="" />
      <div class="font-600 text-18 md:mt-2">Fidsy Unlimited</div>
    </div>
    <div>
      <ul class="list-disc py-5 pl-6 flex-grow">
        <li class="my-1" v-html="$t('subscription.plan_details.unlimited.message_1')"></li>
        <li class="my-1" v-html="$t('subscription.plan_details.unlimited.message_2')"></li>
        <li class="my-1" v-html="$t('subscription.plan_details.unlimited.message_3')"></li>
        <li class="my-1" v-html="$t('subscription.plan_details.unlimited.message_4')"></li>
        <li class="my-1" v-html="$t('subscription.plan_details.unlimited.message_5')"></li>
        <li class="my-1" v-html="$t('subscription.plan_details.unlimited.message_6')"></li>
        <li class="my-1" v-html="$t('subscription.plan_details.unlimited.message_7')"></li>
      </ul>
      <div class="mx-auto w-180">
        <FormSelect
          :value="period"
          ref="period"
          :reduce="(opt) => opt.value"
          outlined
          append-to-body
          rounded
          :options="periods"
          style="--vs-search-input-bg: transparent"
          @input="$emit('onPeriodChange', $event)"
          @click.native.stop.prevent="onClick"
        />
        <div class="text-22 font-500 text-center mt-4">{{ priceText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlanItemUnlimited',
  props: {
    selected: Boolean,
    periods: Array,
    period: String,
    priceText: String,
  },
  computed: {
    logo() {
      return process.env.VUE_APP_BUBBLE_ICON;
    },
  },
  methods: {
    onClick() {
      this.$refs.period.$refs.vselect.open = true;
      this.$refs.period.$refs.vselect.searchEl.focus();
    },
  },
};
</script>

<style scoped lang="scss"></style>
