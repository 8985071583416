<template>
  <div class="rounded-10 shadow-card px-4 flex" :class="{ [heightClass]: true, [directionClass]: true }" @click.stop="$emit('click')">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ListCard',
  props: {
    heightClass: {
      type: String,
      default: 'h-10',
    },
    directionClass: {
      type: String,
      default: 'flex-row items-center',
    },
  },
};
</script>
