import { render, staticRenderFns } from "./CreateProjectModal.vue?vue&type=template&id=7447f8f9&scoped=true"
import script from "./CreateProjectModal.vue?vue&type=script&lang=js"
export * from "./CreateProjectModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7447f8f9",
  null
  
)

export default component.exports