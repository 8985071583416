<template>
  <div class="pt-4 pb-8 pl-12 pr-12 overflow-y-auto max-h-54">
    <div class="mb-4 text-gray-500 text-xs">{{ $t('account') }}</div>
    <ul v-if="searchList.length > 0">
      <li v-for="account in searchList" :key="account.account_id" class="flex items-center my-3">
        <div
          :class="{
            'flex-1 pl-2 pr-3 h-10 py-2 flex mr-2 hover:bg-purple-200 hover:text-purple-900 hover:border-purple-200': true,
            'border border-solid border-gray-200 rounded-full cursor-pointer truncate overflow-hidden items-center': true,
          }"
        >
          <img :src="getDataSourceIcon[account.type]" class="w-7 h-7 mr-4" />
          <span class="flex-grow">{{ account.name }}</span>
          <span>{{ account.email }}</span>
        </div>
      </li>
    </ul>
    <ul v-else>
      <li>{{ $t('accounts_dropdown.message_search_no_accounts') }}</li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DataSourceSearchList',
  props: {
    accountSearchText: [String, Number],
  },
  computed: {
    ...mapState(['data_sources', 'availableDataSources']),
    getDataSourceIcon() {
      const icons = {};
      Object.keys(this.data_sources).forEach((type) => {
        icons[type] = this.availableDataSources[type].logo;
      });
      return icons;
    },
    searchList() {
      let accounts = [];
      Object.values(this.data_sources).forEach((item) => {
        Object.values(item).forEach((emailItem) => {
          accounts = [...accounts, ...Object.values(emailItem.accounts)];
        });
      });
      accounts = accounts.filter((acc) => acc.name.toLowerCase().includes(this.accountSearchText.toLowerCase()));
      return accounts;
    },
  },
};
</script>

<style scoped></style>
