<template>
  <div>
    <FormLabel :label="label" :error="!!error" />
    <v-select
      :id="id"
      ref="vselect"
      class="w-full"
      :style="{ '--vs-border-color': !!error ? 'var(--color-alert)' : '' }"
      :disabled="disabled"
      :value="value"
      :options="options"
      :clearable="clearable"
      :searchable="searchable"
      :reduce="reduce"
      :appendToBody="appendToBody"
      :outlined="outlined"
      :rounded="rounded"
      :elevated="elevated"
      :dense="dense"
      :multiple="multiple"
      @input="updateValue"
      @blur="$emit('blur', $event)"
      @keyup="$emit('keyup', $event)"
      @option:selected="$emit('option:selected', $event)"
    ></v-select>

    <label v-if="error || $slots['error']" class="text-12 text-alert mt-3 mb-1">{{ error }} <slot name="error"></slot></label>
  </div>
</template>

<script>
import ValidationMixin from '@/mixins/ValidationMixin';
import FormLabel from '@/components/ui/FormLabel.vue';

export default {
  name: 'FormSelect',
  components: { FormLabel },
  mixins: [ValidationMixin],
  props: {
    disabled: Boolean,
    value: {
      type: [String, Number, Object, Array],
    },
    options: Array,
    clearable: Boolean,
    searchable: Boolean,
    appendToBody: Boolean,
    reduce: Function,
    label: String,
    error: String,
    elevated: Boolean,
    outlined: Boolean,
    rounded: Boolean,
    dense: Boolean,
    id: String,
    multiple: Boolean,
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>
