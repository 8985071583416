<template>
  <div class="pl-4 pr-4 pt-4 mb-5 md:pt-0 md:mb-10 md:pl-0 md:pr-10">
    <div class="w-full">
      <div class="font-500 text-18">{{ $t('settings.change_password') }}</div>
      <div class="mt-4 mb-10 w-full">
        <ValidationObserver v-slot="{ validate, errors }" class="w-full">
          <FormInput
            class="mt-4 max-w-400"
            v-model="password"
            name="password"
            :rules="[{ validate: arePasswordsEqual }, 'required', 'password']"
            :error="errors && errors['password']"
            :label="$t('login.password')"
            type="password"
          />

          <FormInput
            class="mt-4 mb-3 max-w-400"
            v-model="confirmPassword"
            name="confirmPassword"
            :rules="[{ validate: arePasswordsEqual }, 'required']"
            :error="errors && errors['confirmPassword']"
            :label="$t('login.repeat_password')"
            type="password"
          />

          <div class="w-full flex">
            <PillButton :loading="loading" :disabled="!password || !confirmPassword || loading" class="mt-5 min-w-100" :text="$t('save')" primary @click="onSubmit(validate)"></PillButton>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <Modal v-if="showGoToLoginModal" sheetbelowsm :title="$t('login.password_changed')">
      <div class="px-10 my-6 w-full sm:w-450 text-left">
        {{ $t('settings.login_again') }}
      </div>
      <div slot="footer">
        <div class="w-full flex py-4 px-10">
          <PillButton primary :text="$t('settings.go_to_login')" class="ml-auto" @click="goToLogin" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ValidationObserver from '@/components/ui/ValidationObserver.vue';
import { apiPostChangePassword } from '@/helpers/api';
import { same } from '@/helpers/validators';

export default {
  name: 'Profile',
  components: { ValidationObserver },
  data() {
    return {
      password: '',
      confirmPassword: '',
      loading: false,
      showGoToLoginModal: false,
    };
  },
  methods: {
    ...mapActions(['showToastMessage']),
    arePasswordsEqual() {
      return same(this.password, this.confirmPassword) || this.$t('login.passwords_must_be_same');
    },
    async onSubmit(validate) {
      if (validate && typeof validate === 'function') {
        const result = await validate();
        if (!result.valid) return;
      }
      try {
        this.loading = true;
        const response = await apiPostChangePassword({ password: this.password });
        this.loading = false;

        if (response.status === 200) {
          this.showGoToLoginModal = true;
        } else {
          this.showToastMessage({ message: response.data.message || this.$t('settings.failed_to_reset_password'), type: 'error' });
        }
      } catch {
        this.loading = false;
        this.showToastMessage({ message: this.$t('settings.failed_to_reset_password'), type: 'error' });
      }
    },
    goToLogin() {
      this.$router.push('/login');
    },
  },
};
</script>
