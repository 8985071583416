<template>
  <div class="navigation-item w-full flex items-center overflow-hidden cursor-pointer relative select-none" :style="{ backgroundColor: selected ? '#3F2E9B' : '' }" v-on="$listeners">
    <div class="w-18 h-18 flex justify-center items-center flex-shrink-0">
      <Icon :name="icon" class="w-8 h-8 text-white flex items-center justify-center" :class="{ 'opacity-70': !selected, [iconClass]: true }" />
    </div>
    <span v-if="text" class="text-white text-18">{{ text }}</span>
    <div v-if="selected" class="selected-indicator absolute w-2 h-18 bg-white rounded-l-6" style="right: -5px"></div>
  </div>
</template>

<script>
export default {
  name: 'NavigationItem',
  props: {
    text: String,
    icon: String,
    iconClass: String,
    selected: Boolean,
  },
};
</script>
<style lang="scss"></style>
