<template>
  <v-select
    :value="org"
    placeholder="Select"
    class="org-select w-[140px]"
    :searchable="false"
    :clearable="false"
    :reduce="(opt) => opt.value"
    rounded
    :alternative-indicator="alternativeIndicator"
    :options="organisations"
    @option:selected="optionSelected"
  ></v-select>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'OrganisationSelect',
  props: {
    alternativeIndicator: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState(['org', 'organisations']),
  },
  methods: {
    ...mapActions(['setTenantOrganisation']),
    optionSelected(item) {
      this.setTenantOrganisation(item.value);
    },
  },
};
</script>
<style lang="scss">
.org-select {
  --vs-border-color: #{var(--color-purple-200)} !important;

  .vs__dropdown-toggle {
    padding: 0 6px 4px 8px;
  }
}
</style>
