<template>
  <FadeTransition>
    <div
      class="fixed z-100 bottom-0 top-0 inset-x-0 inset-0 flex items-center justify-center min-w-320 backdrop-blur-sm backdrop-filter"
      :class="{ 'px-4 pb-4': !fullbelowsm && !sheetbelowsm, 'pointer-events-none': primaryButtonLoading }"
    >
      <div class="fixed inset-0">
        <div class="absolute inset-0 bg-gray-400 opacity-75"></div>
      </div>
      <FadeTransition>
        <div
          class="bg-white overflow-hidden transform transition-all duration-300 ease-in-out"
          :class="{
            'h-full sm:h-auto sm:rounded-20 sm:shadow-xl w-full': fullbelowsm,
            'rounded-20 shadow-xl max-h-screen': !fullbelowsm && !sheetbelowsm,
            'mt-auto sm:my-auto max-sm:w-full rounded-t-20 sm:rounded-t-26 sm:rounded-b-26 shadow-xl max-h-screen': sheetbelowsm,
            'max-sm:translate-y-full': !mounted && sheetbelowsm,
            [containerClass]: true,
          }"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white w-full overflow-hidden flex flex-col" :class="{ 'h-full sm:h-auto': fullbelowsm, 'max-h-screen': !fullbelowsm }">
            <template v-if="title || closeButton">
              <div class="pt-10 px-10 flex items-center justify-between mb-2">
                <div v-if="title" class="relative font-600 text-20">
                  {{ title }}
                </div>

                <div
                  v-if="closeButton"
                  class="rounded-full w-6 h-6 flex justify-center items-center cursor-pointer ml-auto"
                  :class="{ 'right-5': title, 'top-5': title, 'right-3': !title, 'top-1': !title }"
                  @click.stop="$emit('close')"
                >
                  <Icon name="clear" class="w-5 h-5" />
                </div>
              </div>
            </template>
            <div class="overflow-auto flex-grow" :class="{ [overflowContainerClass]: true }">
              <slot></slot>
            </div>
            <template v-if="footer">
              <Divider dense class="mt-3" />
              <div class="w-full">
                <slot name="footer"></slot>
              </div>
            </template>
            <template v-else-if="primaryButton || secondaryButton">
              <Divider dense class="mt-5" />
              <div class="w-full flex py-4 px-10">
                <PillButton v-if="secondaryButton" :text="secondaryButton" :disabled="primaryButtonLoading || secondaryButtonDisabled" outlined @click="$emit('secondary', $event)" />
                <PillButton
                  v-if="primaryButton"
                  primary
                  :loading="primaryButtonLoading"
                  :disabled="primaryButtonLoading || primaryButtonDisabled"
                  class="ml-auto"
                  :text="primaryButton"
                  outlined
                  @click="$emit('primary', $event)"
                />
              </div>
            </template>
          </div>
        </div>
      </FadeTransition>
    </div>
  </FadeTransition>
</template>

<script>
import FadeTransition from '@/components/transitions/FadeTransition.vue';

export default {
  name: 'Modal',
  components: {
    FadeTransition,
  },
  props: {
    containerClass: {
      type: String,
      default: 'sm:w-auto',
    },
    overflowContainerClass: {
      type: String,
      default: '',
    },
    fullbelowsm: Boolean,
    sheetbelowsm: Boolean,
    title: {
      type: String,
    },
    closeButton: {
      type: Boolean,
    },
    primaryButton: {
      type: String,
    },
    primaryButtonDisabled: {
      type: Boolean,
    },
    primaryButtonLoading: {
      type: Boolean,
    },
    secondaryButton: {
      type: String,
    },
    secondaryButtonDisabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    footer() {
      return this.$slots?.footer;
    },
  },
  mounted() {
    document.body.classList.add('overflow-hidden');
    this.$emit('after-enter');
    setTimeout(() => {
      this.mounted = true;
      if (document.querySelector('.bottom-navigation')) {
        document.querySelector('.bottom-navigation').classList.add('hidden');
      }
    }, 0);
  },
  beforeDestroy() {
    document.body.classList.remove('overflow-hidden');
    if (document.querySelector('.bottom-navigation')) {
      document.querySelector('.bottom-navigation').classList.remove('hidden');
    }
  },
};
</script>
