<template>
  <div class="flex mb-4 flex-wrap lg:flex-nowrap">
    <div class="flex-grow w-full sm:w-1/2 px-4 sm:px-12 pb-4 sm:pb-8 pt-4">
      <div class="mb-0 sm:mb-4 text-gray-500 text-xs">{{ $t('data_source') }}</div>
      <ul v-if="dataSources.length > 0" class="overflow-y-auto max-h-54">
        <li
          v-for="(ds, index) in dataSources"
          :key="`${ds.type}-${ds.email}-${index}`"
          :class="[listItemClasses, ds.active ? 'bg-primary text-white border-primary' : ''].join(' ')"
          class="my-3 flex align-center px-1 h-10"
          @click="
            clickedDataSourceType = ds.type;
            clickedDataSourceEmail = ds.email;
          "
        >
          <img :src="ds.icon" class="w-7 h-7 mr-4" :alt="ds.name" />
          <span class="truncate flex-1">{{ ds.name }}</span>
          <icon name="chevron_right" class="w-6 h-6" v-if="ds.active"></icon>
        </li>
      </ul>
      <div v-else>
        <span>{{ $t('accounts_dropdown.message_no_accounts') }}. </span>
        <router-link to="/user/datasources" class="text-primary no-underline">{{ $t('accounts_dropdown.click_to_add_datasource') }}</router-link>
      </div>
    </div>
    <div class="flex-grow w-full sm:w-1/2 px-4 sm:px-12 pb-4 sm:pb-8 pt-4 border-l border-gray-200" v-if="dataSources.length > 0">
      <div class="mb-0 sm:mb-4 flex justify-between">
        <div class="text-gray-500 text-xs">{{ $t('account') }}</div>
      </div>
      <ul class="overflow-y-auto max-h-54">
        <li v-for="account in accounts" :key="account.account_id" class="flex items-center my-3" @click="onClickAccount(account)">
          <div :class="[listItemClasses, account.active ? 'bg-primary text-white border-primary' : ''].join(' ')" class="flex-1 pl-6 pr-3 h-10 py-2 flex mr-2">
            <span class="flex-1">{{ account.name }}</span>
            <spinner v-if="loadingId === account.account_id" class="w-5 h-5" />
          </div>
          <div class="cursor-pointer favourite-button" v-if="false">
            <icon name="favorite_star_filled"></icon>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'DataSourceAccountSelection',
  props: {
    isPanelOpen: Boolean,
  },
  data() {
    return {
      clickedDataSourceType: null,
      clickedDataSourceEmail: null,
      loadingId: null,
    };
  },
  computed: {
    ...mapState(['data_sources', 'activeDatasourceType', 'activeDatasourceDatasourceId']),
    ...mapState({
      DATA_SOURCE_TYPES: 'availableDataSources',
    }),
    ...mapGetters(['getActiveAccount']),
    availableDataSources() {
      const notRevokedDataSources = {};
      Object.keys(this.DATA_SOURCE_TYPES).forEach((dataSourceType) => {
        notRevokedDataSources[dataSourceType] = {};
        const emails = this.data_sources[dataSourceType];
        if (emails) {
          Object.keys(emails).forEach((email) => {
            if (!emails[email].revoked) {
              notRevokedDataSources[dataSourceType][email] = emails[email];
            }
          });
          if (Object.keys(notRevokedDataSources[dataSourceType]) === 0) {
            delete notRevokedDataSources[dataSourceType];
          }
        }
      });
      return notRevokedDataSources;
    },
    selectedAccountType() {
      const { clickedDataSourceType, availableDataSources } = this;
      const dataSourceTypes = Object.keys(availableDataSources);
      const activeAccount = this.getActiveAccount;

      if (clickedDataSourceType) {
        return clickedDataSourceType;
      }
      if (activeAccount && activeAccount.type) {
        return activeAccount.type;
      }
      if (dataSourceTypes.length) {
        return dataSourceTypes[0];
      }
      return null;
    },
    selectedAccountEmail() {
      const { selectedAccountType, clickedDataSourceEmail, availableDataSources } = this;
      const activeAccount = this.getActiveAccount;

      if (clickedDataSourceEmail) {
        return clickedDataSourceEmail;
      }
      if (activeAccount && activeAccount.email) {
        return activeAccount.email;
      }
      if (selectedAccountType in availableDataSources) {
        const emails = Object.keys(availableDataSources[selectedAccountType]);
        if (emails.length) {
          return emails[0];
        }
      }
      return null;
    },
    dataSources() {
      const { selectedAccountType, selectedAccountEmail } = this;
      const dataSourceTypes = Object.keys(this.availableDataSources);

      return dataSourceTypes
        .map((type) => {
          return Object.keys(this.availableDataSources[type]).map((email) => ({
            type,
            email,
            name: this.DATA_SOURCE_TYPES[type].name,
            icon: this.DATA_SOURCE_TYPES[type].logo,
            active: type === selectedAccountType && email === selectedAccountEmail,
          }));
        })
        .flat();
    },
    accounts() {
      const { selectedAccountType, selectedAccountEmail, availableDataSources } = this;
      const activeAccount = this.getActiveAccount;

      if (!selectedAccountType || !selectedAccountEmail) {
        return [];
      }

      const { accounts } = availableDataSources[selectedAccountType][selectedAccountEmail];
      if (accounts) {
        return Object.values(accounts).map((account) => ({
          ...account,
          active: activeAccount ? account.account_id === activeAccount.account_id : false,
        }));
      }

      return [];
    },
    listItemClasses() {
      return 'hover:bg-purple-200 hover:text-purple-900 hover:border-purple-200 border border-solid border-gray-200 rounded-full cursor-pointer truncate overflow-hidden items-center';
    },
  },
  methods: {
    ...mapActions(['setActiveConversationAccount', 'fetchConversationSuggestions']),
    async onClickAccount(account) {
      if (!this.loadingId) {
        this.loadingId = account.account_id;
        try {
          await this.setActiveConversationAccount(account);
          this.fetchConversationSuggestions();
        } finally {
          this.loadingId = null;
          this.$emit('close');
        }
      }
    },
  },
  watch: {
    isPanelOpen(value) {
      if (!value) {
        this.clickedDataSourceType = null;
        this.clickedDataSourceEmail = null;
      }
    },
  },
};
</script>

<style lang="scss">
.favourite-button {
  color: transparent;

  &--active,
  &:hover {
    color: #f2c94c;
  }
}
</style>
