<template>
  <div class="flex-grow">
    <label v-if="label" class="text-gray-700 text-13 mb-1 inline-block">{{ label }}</label>
    <v-select
      v-model="variable"
      :dense="dense"
      ref="select"
      :class="[selectBgColor]"
      class="flex-grow"
      :placeholder="$t('evaluate.select_variable')"
      :options="options"
      :clearable="clearable"
      :multiple="multiple"
      :appendToBody="appendToBody"
      :reduce="(option) => option.value"
      @option:selected="$emit('option:selected', $event)"
      @input="$emit('input', $event)"
      @open="onOpen"
      @close="onClose"
    >
      <template v-if="showCreate" v-slot:list-footer>
        <div
          style="bottom: -1px"
          class="flex sticky justify-center w-full cursor-pointer py-3 bg-gray-100 text-indigo-600 hover:text-indigo-800 border-t border-solid border-gray-200"
          @click="createVariable"
        >
          {{ $t('collections.create_variable') }}
        </div>
      </template>
      <template v-slot:option="item">
        <div class="flex justify-between items-center">
          <div>{{ item.label }}</div>
          <Badge v-if="item.variable && item.variable.dataType" :text="item.variable.dataType" info />
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import AddEditVariable from '@/components/intent-editor/AddEditVariable';
import { VARIABLE_TYPE } from '@/constants';
import CreateProjectVariable from '@/components/no-code/CreateProjectVariable';

export default {
  name: 'VariableSelect',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {},
    label: {
      type: String,
    },
    selectBgColor: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    system: {
      type: Boolean,
      default: true,
    },
    showCreate: {
      type: Boolean,
      default: true,
    },
    excludes: {
      type: Array,
      default: () => [],
    },
    filterDataType: {
      type: Array,
      default: () => [],
    },
    curly: {
      type: Boolean,
    },
    filter: {
      type: Function,
    },
    multiple: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
  },
  inject: {
    variableSelectorType: { default: 'conversation' },
  },
  data() {
    return {
      variable: null,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType']),
    ...mapGetters(['getActiveDatasourceVariables', 'getNoCodeProjectVariables']),
    variables() {
      if (this.variableSelectorType === 'no-code') {
        return this.getNoCodeProjectVariables;
      }
      return this.getActiveDatasourceVariables;
    },
    variablesArr() {
      const variables = Object.keys(this.variables).map((v) => ({
        name: v,
        ...this.variables[v],
      }));

      if (!this.system) {
        return variables.filter((v) => !v?.variableType || v?.variableType !== VARIABLE_TYPE.SYSTEM);
      }

      return variables;
    },
    options() {
      let variables = this.variablesArr.filter((v) => !this.excludes.includes(v.name));

      if (this.filter) {
        variables = variables.filter(this.filter);
      }

      if (this.filterDataType?.length) {
        variables = variables.filter((v) => this.filterDataType.includes(v.dataType));
      }

      variables = variables.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      if (this.curly) {
        return variables.map((v) => ({ label: v.name, value: `{${v.name}}`, variable: v }));
      }
      return variables.map((v) => ({ label: v.name, value: v.name, variable: v }));
    },
  },
  methods: {
    ...mapActions(['fetchDesignTimeData', 'showToastMessage', 'fetchProjectData']),
    onOpen() {
      document.body.style.setProperty('--vs-dropdown-z-index', '100000');
    },
    onClose() {
      document.body.style.removeProperty('--vs-dropdown-z-index');
    },
    createVariable() {
      this.$refs.select.open = false;
      if (this.variableSelectorType === 'no-code') {
        this.$showModal(CreateProjectVariable, { projectId: this.$route.params.id }, { save: this.onVariableSaved });
      } else {
        this.$showModal(AddEditVariable, {}, { saveintent: this.onVariableSaved });
      }
    },
    async onVariableSaved(response) {
      try {
        if (this.variableSelectorType === 'no-code') {
          await this.fetchProjectData(this.$route.params.id);
        } else {
          await this.fetchDesignTimeData();
        }
      } catch (e) {
        this.showToastMessage({ title: this.$t('projects.variables.failed_fetch'), type: 'error' });
      }
      this.variable = response.name;
    },
  },
  watch: {
    variable() {
      this.$emit('input', this.variable);
    },
    value: {
      handler() {
        this.variable = this.value || null;
      },
      immediate: true,
    },
  },
};
</script>
