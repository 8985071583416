import { del, get, post, put } from './fetch';

let store;

export function setStore(vuexStore) {
  store = vuexStore;
}

async function ResponseAdapter(response) {
  switch (response.status) {
    case 200:
    case 400:
    case 401:
    case 403:
      try {
        let currentUser = response.headers.get('Current-User');
        if (currentUser) {
          currentUser = JSON.parse(currentUser);
        }
        const parsedResponse = await response.json();
        return { status: response.status, data: parsedResponse, currentUser };
      } catch (e) {
        return {
          status: response.status,
          message: e.message,
        };
      } finally {
        if (response.status === 401) {
          store.dispatch('logout');
        }
      }
    case 500:
      try {
        const parsedResponse = await response.json();
        return {
          status: response.status,
          data: {
            error: true,
            message: parsedResponse.message,
          },
        };
      } catch (e) {
        return {
          status: response.status,
          data: {
            error: true,
            message: e.message,
          },
        };
      }
    default:
      break;
  }

  return {
    status: response.status,
    message: response?.data?.message || 'UNKNOWN_DATA',
  };
}

export async function apiPostLogout() {
  const response = await post('/api/v1/auth/logout');
  return ResponseAdapter(response);
}
export async function apiGetAuthProviders() {
  const response = await get('/api/v1/auth/providers');
  return ResponseAdapter(response);
}
export async function apiDeleteAccount() {
  const response = await del('/api/v1/auth/account');
  return ResponseAdapter(response);
}
export async function apiPostChangePassword({ password }) {
  const response = await post('/api/v1/auth/changepassword', { password });
  return ResponseAdapter(response);
}

export async function apiGetTenant() {
  const response = await get('/api/v1/tenant');
  return ResponseAdapter(response);
}

export async function apiGetAddresses() {
  const response = await get('/api/v1/tenant/subscription/addresslist');
  return ResponseAdapter(response);
}
export async function apiPutAddress({ address_id, description, first_line, second_line, city, postal_code, region, country_code, status, custom_data }) {
  const response = await put('/api/v1/tenant/subscription/address', { address_id, description, first_line, second_line, city, postal_code, region, country_code, status, custom_data });
  return ResponseAdapter(response);
}
export async function apiChangeSubscriptionAddress({ address_id }) {
  const response = await post('/api/v1/tenant/subscription/billingaddress', { address_id });
  return ResponseAdapter(response);
}

export async function apiGetPayments() {
  const response = await get('/api/v1/tenant/subscription/payments');
  return ResponseAdapter(response);
}

export async function apiPostCancelSubscription() {
  const response = await post('/api/v1/tenant/subscription/cancel');
  return ResponseAdapter(response);
}

export async function apiPostPauseSubscription({ type, subscription_id, paused }) {
  const response = await post('/api/v1/tenant/subscription/pause', { type, subscription_id, paused });
  return ResponseAdapter(response);
}

export async function apiPostResumeSubscription() {
  const response = await post('/api/v1/tenant/subscription/cancelscheduled');
  return ResponseAdapter(response);
}
export async function apiPostUpdateSubscription({ price_id }) {
  const response = await post('/api/v1/tenant/subscription/update', { price_id });
  return ResponseAdapter(response);
}
export async function apiPostUpdatePreviewSubscription({ price_id }) {
  const response = await post('/api/v1/tenant/subscription/preview', { price_id });
  return ResponseAdapter(response);
}
export async function apiGetInvoice({ transaction_id }) {
  const response = await get(`/api/v1/tenant/subscription/invoice?transaction_id=${transaction_id}`);
  return ResponseAdapter(response);
}

export async function apiPostAdminDatasourceRevoke({ datasource_id, type }) {
  const response = await post('/api/v1/datasource/revoke', { datasource_id, type });
  return ResponseAdapter(response);
}

export async function apiPostDatasourceAccount({ type, datasource_id, account }) {
  const response = await post('/api/v1/datasource/account', { type, datasource_id, account });
  return ResponseAdapter(response);
}

export async function apiDeleteDatasourceAccount({ type, datasource_id, account_id }) {
  const response = await post('/api/v1/datasource/account/remove', { type, datasource_id, account_id });
  return ResponseAdapter(response);
}

export async function apiGetDatasourceAccounts(type, datasource_id) {
  const response = await get(`/api/v1/datasource/accounts?type=${type}&datasource_id=${datasource_id}`);
  return ResponseAdapter(response);
}

export async function apiPostIntentQuery({ phrase, event }) {
  const response = await post('/api/v1/conversations/web', { phrase, event });
  return ResponseAdapter(response);
}

export async function apiPostEvaluate({ type, datasource_id, account_id, evaluate, model_id }) {
  const res = await post('/api/v1/evaluate', { type, datasource_id, account_id, evaluate, model_id });
  return ResponseAdapter(res);
}

export async function apiPostCollectionsQuery({ type, datasource_id, account_id, id, collection_id }) {
  const res = await post('/api/v1/collection/query', { type, datasource_id, account_id, collection_id, id });
  return ResponseAdapter(res);
}

export async function apiPostCollectionPreview({ phrase, type, datasource_id, account_id, date_range }) {
  const res = await post('/api/v1/collection/preview', { phrase, type, datasource_id, account_id, date_range });
  return ResponseAdapter(res);
}

export async function apiPostDatasourceDefaults(payload) {
  const response = await post(`/api/v1/datasource/defaults`, payload);
  return ResponseAdapter(response);
}

export async function apiGetConversationDefinition({ name, type, model_id }) {
  const response = await get(`/api/v1/conversation?name=${name}&type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiPostConversationDefinition({ name, type, model_id, steps, transition }) {
  const response = await post('/api/v1/conversation', { name, type, model_id, steps, transition });
  return ResponseAdapter(response);
}

export async function apiPutConversationDefinition({ name, type, steps, transition, model_id }) {
  const response = await put('/api/v1/conversation', { name, type, steps, transition, model_id });
  return ResponseAdapter(response);
}

export async function apiDeleteConversationDefinition({ name, type, model_id }) {
  const response = await del(`/api/v1/conversation?name=${name}&type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiGetVariableDefinition({ name, type, model_id }) {
  const response = await get(`/api/v1/variable?name=${name}&type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiPostVariableDefinition({ name, type, dataType, value, model_id }) {
  const response = await post('/api/v1/variable', { name, type, dataType, value, model_id });
  return ResponseAdapter(response);
}

export async function apiPutVariableDefinition({ name, type, dataType, value, model_id }) {
  const response = await put('/api/v1/variable', { name, type, dataType, value, model_id });
  return ResponseAdapter(response);
}

export async function apiDeleteVariableDefinition({ name, type, model_id }) {
  const response = await del(`/api/v1/variable?name=${name}&type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiGetFineTuneData({ type, model_id }) {
  const response = await get(`/api/v1/finetunedata?type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiPostFineTuneData({ data, type, model_id }) {
  const response = await post('/api/v1/finetunedata', { data, type, model_id });
  return ResponseAdapter(response);
}
export async function apiGetDataType({ name, type, model_id }) {
  const response = await get(`/api/v1/entity?name=${name}&type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiPostDataType({ name, fields, type, colour, model_id }) {
  const response = await post('/api/v1/entity', { name, fields, type, colour, model_id });
  return ResponseAdapter(response);
}

export async function apiPutDataType({ name, fields, type, colour, model_id }) {
  const response = await put('/api/v1/entity', { name, fields, type, colour, model_id });
  return ResponseAdapter(response);
}

export async function apiDeleteEntity({ name, type, model_id }) {
  const response = await del(`/api/v1/entity?name=${name}&type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiGetData({ type, model_id }) {
  const response = await get(`/api/v1/data?type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiPostSendSigninEmailLink({ email, token }) {
  const payload = { email, token };
  if (window.location.host === 'local.fidsy.cloud') {
    payload.local = true;
  }
  const response = await post('/api/v1/auth/loginwithemail', payload);
  return ResponseAdapter(response);
}

export async function apiPostRegister({ password, name, timezone, picture, country, organisation, position, language }) {
  const payload = { password, name, timezone, picture, country, organisation, position, language };
  const response = await post('/api/v1/auth/register', payload);
  return ResponseAdapter(response);
}

export async function apiPostResetPassword({ email, token, password }) {
  const response = await post('/api/v1/auth/passwordreset', { email, token, password });
  return ResponseAdapter(response);
}
export async function apiPostForgotPassword({ email, token, local = false }) {
  if (window.location.host === 'local.fidsy.cloud') {
    local = true;
  }
  const response = await post('/api/v1/auth/forgotpassword', { email, token, local });
  return ResponseAdapter(response);
}

export async function apiPostLoginWithPassword({ email, password, token }) {
  const payload = { email, password, token };
  return post('/api/v1/auth/loginwithpassword', payload);
}

export async function apiPostFeedback({ sentiment, message, fields, data, phrase }) {
  const response = await post('/api/v1/feedback', { sentiment, message, fields, data, phrase });
  return ResponseAdapter(response);
}

export async function apiPostEmailCardToUser(data) {
  const response = await post('/api/v1/emailcardtouser', data);
  return ResponseAdapter(response);
}

export async function apiPostCollection({ name, digest }) {
  const response = await post('/api/v1/tenant/collection', { collection: name, digest });
  return ResponseAdapter(response);
}

export async function apiPostMoveCollectionQuestion({ id, collection_id, new_collection_id }) {
  const res = await post('/api/v1/tenant/collection/question/move', { id, collection_id, new_collection_id });
  return ResponseAdapter(res);
}

export async function apiPostTenantCollectionQuestion({ phrase, collection_id, type, datasource_id, account_id, interval, variables }) {
  const res = await post('/api/v1/tenant/collection/question', { collection_id, type, datasource_id, account_id, interval, variables, phrase });
  return ResponseAdapter(res);
}
export async function apiPutTenantCollectionQuestion({ phrase, id, collection_id, type, datasource_id, account_id, interval, variables }) {
  const res = await put('/api/v1/tenant/collection/question', { phrase, id, collection_id, type, datasource_id, account_id, interval, variables });
  return ResponseAdapter(res);
}

export async function apiDeleteTenantCollectionQuestion({ collection_id, id }) {
  const response = await del(`/api/v1/tenant/collection/question?collection_id=${collection_id}&id=${id}`);
  return ResponseAdapter(response);
}

export async function apiGetCollectionQuestions(type) {
  const response = await get(`/api/v1/questions?type=${type}`);
  return ResponseAdapter(response);
}

export async function apiPostCollectionQuestion({ type, name, model_id }) {
  const res = await post('/api/v1/question', { type, name, model_id });
  return ResponseAdapter(res);
}

export async function apiPutCollectionQuestion({ type, name, steps, transition, model_id }) {
  const res = await put('/api/v1/question', { type, name, steps, transition, model_id });
  return ResponseAdapter(res);
}

export async function apiDeleteCollectionQuestion({ type, name, model_id }) {
  const response = await del(`/api/v1/question?type=${type}&name=${name}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiGetCollection(collection_id) {
  const response = await get(`/api/v1/collection?collection=${collection_id}`);
  return ResponseAdapter(response);
}

export async function apiPutCollection({ collection_id, name, digest, layout }) {
  const response = await put('/api/v1/tenant/collection', { collection_id, name, digest, layout });
  return ResponseAdapter(response);
}

export async function apiDeleteCollection({ collection_id }) {
  const response = await del(`/api/v1/tenant/collection?collection_id=${collection_id}`);
  return ResponseAdapter(response);
}

export async function apiPutProfile(payload) {
  const response = await put('/api/v1/tenant/profile', payload);
  return ResponseAdapter(response);
}

export async function apiGetFunctions(type, model_id) {
  const response = await get(`/api/v1/functions?type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiGetReleaseEvents(fine_tuning_job_id) {
  const response = await get(`/api/v1/admin/publish/events?fine_tuning_job_id=${fine_tuning_job_id}`);
  return ResponseAdapter(response);
}
export async function apiGetReleases(type, model_id) {
  const response = await get(`/api/v1/admin/datasource/releases?type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiPostRelease({ type, version, trainModel, model_id }) {
  const response = await post(`/api/v1/admin/datasource/release`, { type, version, trainModel, model_id });
  return ResponseAdapter(response);
}
export async function apiPostReleaseCancel({ fine_tuning_job_id, type, version, model_id }) {
  const response = await post(`/api/v1/admin/publish/cancel`, { fine_tuning_job_id, type, version, model_id });
  return ResponseAdapter(response);
}

export async function apiPutFlag({ key, value }) {
  const response = await put(`/api/v1/flag`, { key, value });
  return ResponseAdapter(response);
}

export async function apiSetActiveAccount(payload) {
  const response = await post('/api/v1/conversations/account/select', payload);
  return ResponseAdapter(response);
}

export async function apiGetSuggestions(type) {
  const response = await get(`/api/v1/conversations/suggestions?type=${type}`);
  return ResponseAdapter(response);
}
export async function apiGetConversationSuggestions(type, model_id) {
  const response = await get(`/api/v1/conversations/suggestions?type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}

export async function apiPostConversationSuggestions({ suggestions, type, model_id }) {
  const response = await post('/api/v1/conversation/suggestions', { suggestions, type, model_id });
  return ResponseAdapter(response);
}

export async function apiGetProjectData(id) {
  const response = await get(`/api/v1/project/data?project_id=${id}`);
  return ResponseAdapter(response);
}

export async function apiGetProjects() {
  const response = await get('/api/v1/project/data');
  return ResponseAdapter(response);
}

export async function apiPostProject({ name, tenants, authentication, cors }) {
  const res = await post('/api/v1/project', { name, tenants, authentication, cors });
  return ResponseAdapter(res);
}

export async function apiPutProject({ project_id, name, tenants, authentication, cors }) {
  const res = await put('/api/v1/project', { project_id, name, tenants, authentication, cors });
  return ResponseAdapter(res);
}

export async function apiDeleteProject(project_id) {
  const response = await del(`/api/v1/project?project_id=${project_id}`);
  return ResponseAdapter(response);
}

export async function apiPostProjectEndpoint({ project_id, method, path, group_name, steps }) {
  const res = await post('/api/v1/project/endpoint', { project_id, method, path, group_name, steps });
  return ResponseAdapter(res);
}

export async function apiPostProjectVariable({ project_id, name, dataType }) {
  const res = await post('/api/v1/project/variable', { project_id, name, dataType });
  return ResponseAdapter(res);
}

export async function apiPutProjectVariable({ project_id, name, dataType }) {
  const res = await put('/api/v1/project/variable', { project_id, name, dataType });
  return ResponseAdapter(res);
}

export async function apiDeleteProjectVariable({ project_id, name }) {
  const response = await del(`/api/v1/project/variable?project_id=${project_id}&name=${name}`);
  return ResponseAdapter(response);
}

export async function apiPostProjectSecret({ project_id, name, value }) {
  const res = await post('/api/v1/project/secret', { project_id, name, value });
  return ResponseAdapter(res);
}

export async function apiPutProjectSecret({ project_id, name, value }) {
  const res = await put('/api/v1/project/secret', { project_id, name, value });
  return ResponseAdapter(res);
}

export async function apiDeleteProjectSecret({ project_id, name }) {
  const response = await del(`/api/v1/project/secret?project_id=${project_id}&name=${name}`);
  return ResponseAdapter(response);
}

export async function apiDeleteProjectEndpoint({ project_id, endpoint_id }) {
  const response = await del(`/api/v1/project/endpoint?project_id=${project_id}&endpoint_id=${endpoint_id}`);
  return ResponseAdapter(response);
}

export async function apiPutProjectEndpointDefinition({ project_id, endpoint_id, method, path, group_name, steps, request_params, transition }) {
  const response = await put('/api/v1/project/endpoint', { project_id, endpoint_id, method, path, group_name, steps, request_params, transition });
  return ResponseAdapter(response);
}

export async function apiGetUsers({ page_number = 0 }) {
  const response = await get(`/api/v1/admin/users?pagesize=10&pagenumber=${page_number}`);
  return ResponseAdapter(response);
}

export async function apiGetUserCount() {
  const response = await get(`/api/v1/admin/users/count`);
  return ResponseAdapter(response);
}

export async function apiGetUser({ tenant_id }) {
  const response = await get(`/api/v1/admin/user?tenant_id=${tenant_id}`);
  return ResponseAdapter(response);
}

export async function apiPostAddUser({ email, password, name, timezone, country, organisation, position, language, org, organisations, flags }) {
  const response = await post(`/api/v1/admin/user`, { email, password, name, timezone, country, organisation, position, language, org, organisations, flags });
  return ResponseAdapter(response);
}
export async function apiPutUser({ tenant_id, name, timezone, country, organisation, position, language, flags, organisations }) {
  const response = await put(`/api/v1/admin/user`, { tenant_id, name, timezone, country, organisation, position, language, flags, organisations });
  return ResponseAdapter(response);
}
export async function apiPostDisableUser({ tenant_id, disabled }) {
  const response = await post(`/api/v1/admin/user/disable`, { tenant_id, disabled });
  return ResponseAdapter(response);
}
export async function apiDelDeleteUser({ tenant_id }) {
  const response = await del(`/api/v1/admin/user?tenant_id=${tenant_id}`);
  return ResponseAdapter(response);
}
export async function apiPostUserDataSource({ tenant_id, type, active, properties, name }) {
  const response = await post(`/api/v1/admin/user/datasource`, { tenant_id, type, active, properties, name });
  return ResponseAdapter(response);
}
export async function apiPutUserDataSourceAccount({ tenant_id, type, datasource_id, account_id, properties, name }) {
  const response = await put(`/api/v1/admin/user/datasource/account`, { tenant_id, type, datasource_id, account_id, properties, name });
  return ResponseAdapter(response);
}
export async function apiDelUserDataSourceAccount({ tenant_id, type, datasource_id, account_id }) {
  const response = await del(`/api/v1/admin/user/datasource/account?tenant_id=${tenant_id}&type=${type}&datasource_id=${datasource_id}&account_id=${account_id}`);
  return ResponseAdapter(response);
}
export async function apiPutUserDataSource({ tenant_id, type, datasource_id, active }) {
  const response = await put(`/api/v1/admin/user/datasource`, { tenant_id, type, datasource_id, active });
  return ResponseAdapter(response);
}
export async function apiDelUserDataSource({ tenant_id, type, datasource_id }) {
  const response = await del(`/api/v1/admin/user/datasource?tenant_id=${tenant_id}&type=${type}&datasource_id=${datasource_id}`);
  return ResponseAdapter(response);
}
export async function apiPostEmailTemplate({ project_id, name, text, html }) {
  const res = await post('/api/v1/project/emailtemplate', { project_id, name, text, html });
  return ResponseAdapter(res);
}

export async function apiPutEmailTemplate({ project_id, name, text, html }) {
  const res = await put('/api/v1/project/emailtemplate', { project_id, name, text, html });
  return ResponseAdapter(res);
}

export async function apiDeleteEmailTemplate({ project_id, name }) {
  const res = await del(`/api/v1/project/emailtemplate?project_id=${project_id}&name=${name}`);
  return ResponseAdapter(res);
}

export async function apiGetDataSourceModels(type) {
  const response = await get(`/api/v1/admin/datasource/models?type=${type}`);
  return ResponseAdapter(response);
}

export async function apiPostDataSourceModel({ type, model_id, name, org }) {
  const res = await post('/api/v1/admin/datasource/model', { type, model_id, name, org });
  return ResponseAdapter(res);
}

export async function apiPutDataSourceModel({ type, model_id, name, org }) {
  const res = await put('/api/v1/admin/datasource/model', { type, model_id, name, org });
  return ResponseAdapter(res);
}

export async function apiDeleteDataSourceModel({ type, model_id }) {
  const response = await del(`/api/v1/admin/datasource/model?type=${type}&model_id=${model_id}`);
  return ResponseAdapter(response);
}
export async function apiPutOrganisation({ org }) {
  const res = await put('/api/v1/tenant/organisation', { org });
  return ResponseAdapter(res);
}
export async function apiGetOrganisations() {
  const res = await get('/api/v1/admin/organisations');
  return ResponseAdapter(res);
}
export async function apiGetSelectedAccount() {
  const res = await get('/api/v1/selectedaccount');
  return ResponseAdapter(res);
}
