<template>
  <div class="bg-off-white shadow-card rounded-10 p-2 lg:p-8 cursor-pointer max-md:mt-5" :class="{ 'outline outline-2 outline-primary bg-white': selected }" @click="$emit('click', $event)">
    <div class="flex flex-col items-center justify-center max-md:flex-row mt-3">
      <img :src="logo" class="w-10 md:w-15" alt="" />
      <div class="font-600 text-18 md:mt-2">Fidsy Free</div>
    </div>
    <div>
      <ul class="list-disc py-5 pl-6 flex-grow">
        <li class="my-1">{{ $t('subscription.plan_details.free.message_1') }}</li>
        <li class="my-1" v-html="$t('subscription.plan_details.free.message_2')"></li>
        <li class="my-1" v-html="$t('subscription.plan_details.free.message_3')"></li>
        <li class="my-1" v-html="$t('subscription.plan_details.free.message_4')"></li>
        <li class="my-1">{{ $t('subscription.plan_details.free.message_5') }}</li>
        <li class="my-1">{{ $t('subscription.plan_details.free.message_6') }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlanItemFree',
  props: {
    selected: Boolean,
  },
  computed: {
    logo() {
      return process.env.VUE_APP_BUBBLE_ICON;
    },
  },
};
</script>
