<template>
  <DefaultLayout show-top-bar>
    <template #header>
      <div class="text-17 flex justify-center items-center font-600 text-indigo-700">
        <span class="text-20">{{ $t('projects.title') }}</span>
        <PillButton v-if="projectList.length" class="ml-5 hidden sm:flex" icon="plus" :text="$t('add')" primary @click="openCreateProjectModal" />
        <IconButton v-if="projectList.length" dense class="ml-5 inline sm:hidden" icon="plus" primary @click="openCreateProjectModal" />
      </div>
    </template>
    <div class="w-full h-full flex flex-col overflow-hidden">
      <div v-if="projectList.length" class="flex-grow overflow-auto">
        <div class="w-full p-3 sm:p-5 grid gap-4 grid-cols-collection-mobile sm:grid-cols-collection">
          <ListCard class="h-140 py-2 bg-white rounded-20 cursor-pointer" v-for="project in projectList" :key="project.id" @click="goToProject(project)">
            <div class="flex flex-col w-full h-full">
              <div class="flex py-3 pl-4 pr-2 items-center">
                <div class="flex-grow text-16 font-500 text-gray-900">{{ project.name }}</div>
                <IconButton icon="edit" @click.native.stop="openEditProjectModal(project)" />
                <IconButton icon="delete" class="text-primary" @click.native.stop="openDeleteProjectModal(project)" />
              </div>
              <Divider dense />
              <div v-if="project.endpoints" class="flex p-5 items-center">
                <template v-if="project.endpoints.length === 0">
                  <div class="text-gray-500">{{ $t('projects.endpoints.no_endpoint') }}</div>
                </template>
                <div class="flex" v-else>
                  <div v-for="endpoint in project.endpoints" :key="endpoint.path + endpoint.method" class="flex mr-0.5">
                    <Badge v-if="endpoint.method === 'GET'" info :text="endpoint.path"></Badge>
                    <Badge v-if="endpoint.method === 'POST'" success :text="endpoint.path"></Badge>
                    <Badge v-if="endpoint.method === 'DELETE'" error :text="endpoint.path"></Badge>
                  </div>
                  <Badge class="flex-grow text-13 ml-3" text="+7"></Badge>
                </div>
              </div>
            </div>
          </ListCard>
        </div>
      </div>
      <div v-else class="flex flex-col items-center h-full justify-center pb-40">
        <Icon name="code_off" class="w-20 h-20 mb-5 opacity-50" />
        <div class="my-2 text-22 text-gray-700">{{ $t('projects.you_have_no_project') }}</div>
        <div class="my-2 text-gray-600">{{ $t('projects.create_first_project') }}</div>
        <PillButton class="my-3" :text="$t('projects.create_new')" primary @click="openCreateProjectModal" />
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import CreateProjectModal from '@/components/no-code/CreateProjectModal';

export default {
  name: 'Projects',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(['projects']),
    projectList() {
      return (
        Object.keys(this.projects).map((key) => {
          return {
            id: key,
            ...this.projects[key],
          };
        }) || []
      );
    },
  },
  methods: {
    ...mapActions(['deleteProject', 'fetchProjects', 'showToastMessage']),
    goToProject(project) {
      this.$router.push({ name: 'no-code-endpoints', params: { id: project.id } });
    },
    openCreateProjectModal() {
      this.$showModal(CreateProjectModal);
    },
    openEditProjectModal(project) {
      this.$showModal(CreateProjectModal, { project });
    },
    openDeleteProjectModal(project) {
      this.$showModal(
        DeleteModal,
        {
          subtitle: this.$t('projects.remove_project', { projectName: project.name }),
          info: null,
        },
        { confirm: () => this.onDeleteClicked(project) },
      );
    },
    async onDeleteClicked(project) {
      this.loading = true;
      await this.deleteProject(project.id);
      this.showToastMessage({ message: this.$t('projects.project_removed'), type: 'success' });
      this.loading = false;
    },
  },
};
</script>
